<template>
  <div class="super-container" ref="container">
    <div class="first-half">
      <header>
        <MainMenu />
      </header>
    </div>

    <section class="header" :style="{
      'background-image':
        'url(' + base + '/assets/img/open_positions/core_team.webp)',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': '50% 50%',
    }">
      <div class="inner">
        <h1 class="heading-1">
          Shape the Future of Cycling with Us
        </h1>
        <p>
          Join our dynamic team and drive innovative experiences for cyclists around the world.
        </p>
        <div class="btns">
          <a href="#open-positions" class="btn btn-primary">Explore Open Positions</a>
          <a href="#mission" class="btn btn-secondary">Learn more about us</a>
        </div>
      </div>
      <div class="gradient" />
    </section>

    <section class="slide" data-nth="1">
      <div class="inner">
        <h1 class="heading-1" id="mission">
          Our Mission
          <img class="arrow" :src="base + '/assets/img/open_positions/arrow.svg'" alt="Arrow" />
        </h1>
        <p>
          <i>At Cyclers, we're harnessing the power of AI to redefine cycling navigation. Our vision is a world where
            every cyclist finds their perfect route with a single tap. Come be a part of our revolution!</i>
        </p>
      </div>
    </section>

    <section class="slide" data-nth="2">

      <div class="gallery-wrapper">
        <div class="gallery">
          <img :src="base + '/assets/img/open_positions/team.webp'" alt="Team" />
        </div>
        <div class="gallery-footer" :style="{ 'margin-top': `${galleryBottomMargin}px` }">
          <img v-on:load="calculate()" ref="arch" class="arch"
            :src="base + '/assets/img/open_positions/gallery_footer.svg'" alt="" />
          <div class="bottom">
            <p class="cite">“It’s fun to work on a product which enables people to experience real world adventures.”</p>
            <div class="profile">
              <img :src="base + '/assets/img/open_positions/honza.png'" alt="Profile" />
              <span class="who">Honza</span>
              <span class="position">Software Engineer (Android)</span>
            </div>
          </div>
        </div>
      </div>

    </section>

    <section class="slide" data-nth="3">
      <div class="inner">
        <h1 class="heading-1">
          Why to work with us
          <img class="arrow" :src="base + '/assets/img/open_positions/arrow.svg'" alt="Arrow" />
        </h1>
        <div class="boxes">
          <div class="box">
            <h3 class="heading-box">Meaningful</h3>
            <p class="p-box">
              We do meaningful work. Our app encourages people to live in a way that is healthier both to them and the
              planet.
            </p>
            <div class="img-wrap">
              <img :src="base + '/assets/img/open_positions/meaningful.svg'" alt="Icon" height="70" />
            </div>
          </div>
          <div class="box">
            <h3 class="heading-box">Global Impact</h3>
            <p class="p-box">
              We are a small team. Your work will directly impact our product which is used by millions of users around
              the world.
            </p>
            <div class="img-wrap">
              <img :src="base + '/assets/img/open_positions/global-impact.svg'" alt="Icon" height="70" />
            </div>
          </div>
          <div class="box">
            <h3 class="heading-box">Cutting-edge</h3>
            <p class="p-box">
              We have strong background in AI and we use it on a daily basis to automate away mundane work and focus on
              what only humans can do.
            </p>
            <div class="img-wrap">
              <img :src="base + '/assets/img/open_positions/cutting-edge.svg'" alt="Icon" height="70" />
            </div>
          </div>
          <div class="break"></div>
          <div class="box">
            <h3 class="heading-box">Flexibility</h3>
            <p class="p-box">
              We operate a hybrid working policy and can accommodate you even if you cannot come to the office every day
              (or even every week).
            </p>
            <div class="img-wrap">
              <img :src="base + '/assets/img/open_positions/flexibility.svg'" alt="Icon" height="70" />
            </div>
          </div>
          <div class="box">
            <h3 class="heading-box">Rational</h3>
            <p class="p-box">
              Forget in-office politics. Our decision are based on arguments and data -- not authority.
            </p>
            <div class="img-wrap">
              <img :src="base + '/assets/img/open_positions/rational.svg'" alt="Icon" height="70" />
            </div>
          </div>
        </div>
      </div>
      <div class="why-footer" :style="{ 'margin-top': `${galleryBottomMargin}px` }">
        <img v-on:load="calculate()" ref="arch" class="arch" :src="base + '/assets/img/open_positions/why_footer.svg'"
          alt="" />
        <div class="bottom">
          <p class="cite">“It’s fun to work on a product which enables people to experience real world adventures.”</p>
          <div class="profile">
            <img :src="base + '/assets/img/open_positions/libor.png'" alt="Profile" />
            <span class="who">Honza</span>
            <span class="position">Software Engineer (iOS)</span>
          </div>
        </div>
      </div>
    </section>

    <section class="slide" data-nth="5">
      <div class="inner">
        <h1 class="heading-1" id="open-positions">
          Open positions
          <img class="arrow" :src="base + '/assets/img/open_positions/arrow.svg'" alt="Arrow" />
        </h1>
        <div class="boxes boxes-lg">
          <div class="box box-lg">
            <h3 class="heading-box">Marketing Manager</h3>
            <p class="p-box">
              We’re always on the lookout for branding gurus from the cold northern states that want to work in sunny Florida developing sales and communication programs for our clients.
            </p>
            <div class="text-center">
              <a href="#" class="btn btn-secondary">Learn more</a>
            </div>
          </div>
          <div class="box box-lg">
            <h3 class="heading-box">Senior UX designer</h3>
            <p class="p-box">
              We’re always on the lookout for branding gurus from the cold northern states that want to work in sunny Florida developing sales and communication programs for our clients.
            </p>
            <div class="text-center">
              <a href="#" class="btn btn-secondary">Learn more</a>
            </div>
          </div>
          <div class="box box-lg">
            <h3 class="heading-box">Not seeing the perfect role?</h3>
            <p class="p-box">
              We're always eager to meet passionate individuals. Drop us a line!
            </p>
            <div class="text-center">
              <a class="btn btn-primary">Connect With Us</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer>
      <FooterLinks />
    </footer>

    <GetApp ref="getApp" />
  </div>
</template>

<script>
import { config } from "@/config";
import MainMenu from "@/components/MainMenu.vue";
import FooterLinks from "@/components/FooterLinks.vue";
import GetApp from "@/components/GetApp.vue";

export default {
  name: "Positions",
  components: {
    MainMenu,
    FooterLinks,
    GetApp,
  },
  data() {
    return {
      doc: document,
      config: config,
      base: config.website.base,
      win: window,
      galleryBottomMargin: ''
    };
  },
  mounted() {
    const self = this;

    this.win.addEventListener('resize', () => {
      self.calculate();
    });

  },
  methods: {
    calculate() {
      this.galleryBottomMargin = -1 * (this.$refs.arch.offsetHeight / 2.0);
    }
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/reset.scss";
@import "@/scss/vars.scss";
@import "@/scss/layout.scss";
@import "@/scss/common.scss";
@import "@/scss/conditionals.scss";
@import "@/scss/lines.scss";
@import "@/scss/opacity.scss";
@import "@/scss/colors.scss";
@import "@/scss/typography.scss";
@import "@/scss/forms.scss";
@import "@/scss/animations.scss";
@import "@/scss/ambassador.scss";
</style>

<style scoped lang="scss">
.super-container {
  background: linear-gradient(281.67deg, #DFDFDF 8.59%, rgba(223, 223, 223, 0) 77.49%);
}

.header {
  width: 100%;
  margin: 0 auto 100px auto;
  padding: 0;
  position: relative;
  height: calc(100vh - 62px);
  overflow: hidden;

  .inner {
    max-width: 1280px;
    margin: calc(50vh - 62px) auto 0 auto;
    position: relative;
    z-index: 10;
    padding: 0 10px;

    .heading-1 {
      font-family: Poppins;
      font-size: 50px;
      font-weight: 600;
      line-height: 90px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;

    }

    p {
      font-family: Poppins;
      font-size: 25px;
      font-weight: 400;
      line-height: 38px;
      letter-spacing: 0em;
      text-align: left;
      color: #FFFFFF;
    }
  }

  .gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to top,
        rgba(0, 0, 0, .90),
        rgba(0, 0, 0, 0));
    z-index: 0;
  }

  .btns {
    margin: 45px 0 0 0;

    .btn {
      border: 2px solid #267A3E;
      padding: 10px 30px;
      border-radius: 38px;
      gap: 10px;
      color: white;
      font-size: 24px;
      text-decoration: none;

      &:nth-of-type(1) {
        margin-right: 25px;
      }

      &.btn-primary {
        background-color: #267A3E;
      }

      &.btn-secondary {
        background-color: transparent;
      }
    }
  }


}

.slide {
  color: var(--color-dark, #1f3b2f);
  font-variant-numeric: lining-nums proportional-nums;
  padding: 30px 0px;

  .inner {
    max-width: 1050px;
    margin: 0 auto;
    position: relative;
  }

  .gallery-wrapper {
    .gallery {
      img {
        width: 100%;
      }
    }

    .gallery-footer {
      text-align: center;
      color: white;
      margin-top: 129px;
      padding: 0px 0 50px 0;

      img.arch {
        width: 100%;
      }

      .bottom {
        background-color: #203b2f;
        margin-top: -5px;
        padding-bottom: 50px;
      }

      p.cite {
        font-size: 24px;
        font-style: italic;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        max-width: 750px;
        margin: 0 auto;
      }
    }
  }

  .why-footer {
    text-align: center;
    color: white;
    margin-top: 129px;
    padding: 0px 0 50px 0;

    img.arch {
      width: 100%;
    }

    .bottom {
      background-color: #267a3d;
      margin-top: -5px;
      padding-bottom: 50px;
    }

    p.cite {
      font-size: 24px;
      font-style: italic;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      max-width: 750px;
      margin: 0 auto;
    }
  }

  .profile {
    display: inline-block;
    width: 315px;
    margin: 25px auto auto auto;
    text-align: left;
    padding: 10px;

    .who,
    .position {
      display: block;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
    }

    .who {
      margin-top: 10px;
    }

    img {
      border-radius: 50%;
      width: 70px;
      height: 70px;
      float: left;
      margin-right: 15px;
    }
  }

  &.gray {
    background: linear-gradient(130deg,
        #dfdfdf4f 0%,
        rgba(223, 223, 223, 0) 100%);
  }

  &.custom-separator:not(.on-top) {
    padding-bottom: 0;
  }

  &.custom-separator.on-top {
    padding-top: 0;
  }

  .separator {
    width: 100%;
  }

  .heading-1 {
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 32.44px;
    /* 81.099% */
    position: relative;
    display: inline-block;
    margin-bottom: 35px;

    .ico {
      vertical-align: text-bottom;
      margin-left: 30px;
      width: 68px;
    }
  }

  .heading-2 {
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 32.44px;
    /* 101.374% */
    margin-top: 25px;
    margin-bottom: 50px;
  }

  .arrow {
    position: absolute;
    left: -50px;
    top: -20px;
  }

  p {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    /* 141.667% */
    margin-top: 25px;
  }

  .img-wrap {
    text-align: center;
    margin-top: 25px;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
  }

  ul,
  ol {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 166.667% */
    margin-bottom: 75px;
    margin-left: 20px;
  }

  ol {
    margin-left: 25px;
  }

  ul {
    li {
      list-style-type: initial;
    }
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }

  .btn {
    font-family: Poppins;
    border-radius: 30px;
    background: rgba(38, 122, 62, 1);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    text-align: center;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 13.131px;
    /* 50.484% */
    padding: 20px 40px;
    border: 0;
    display: inline-block;
    margin: 25px 0 10px 0;
    text-decoration: none;

    &:hover {
      background: var(--color-primary);
    }

    &.btn-secondary {
      color: var(--color-dark, #1f3b2f);
      background-color: transparent;
      border: 1px solid rgba(38, 122, 62, 1);
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 400;
      margin: 20px 0 10px 0;
    }
  }

  .boxes {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 60px 0 100px 0;

    .box {
      width: 230px;
      height: 275px;
      padding: 15px 30px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
      display: inline-block;
      margin: 20px;
      border-radius: 16px;
      position: relative;

      .heading-box {
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32.44px;
        /* 135.165% */
        margin-top: 5px;
      }

      .p-box {
        color: #657d76;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        /* 150% */
        margin-top: 10px;
      }
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }
  }

  .boxes-lg {
    .box-lg {
      width: 100%;
      height: auto;
      max-width: 850px;
    }
  }

  &[data-nth="1"] {
    padding-top: 0;
  }

  &[data-nth="2"] {
    .picture {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &[data-nth="4"] {
    .ico {
      width: 50px;
      vertical-align: text-top;
    }
  }
}

@media (max-width: 1199px) {
  .slide {
    .inner {
      padding: 30px 30px;


    }
  }
}

@media (max-width: 1099px) {
  .slide {
    .inner {
      .picture {
        display: none;
      }
    }
  }
}

@media (max-width: 639px) {
  .header {
    .inner {
      margin-top: 150px;

      .heading-1 {
        font-size: 30px;
        line-height: 32px;
      }

      p {
        font-size: 18px;
      }
    }
  }

  .slide {
    .inner {
      .heading-1 {
        font-size: 30px;
        line-height: 32px;
        position: relative;
        display: block;
        padding-right: 25px;

        .ico {
          width: auto;
          height: 35px;
          position: absolute;
          right: 0px;
          top: -5px;
        }
      }

      .heading-2 {
        font-size: 22px;
        line-height: 25.44px;
      }

      // .arrow {
      //   transform: scale(1, 1) !important;
      // }
    }
  }
}

#mission,
#open-positions {
  display: block;
  padding-top: 100px;
  margin-top: -100px;

  .arrow {
    top: 80px;
  }
}
</style>

<style lang="scss">
@media (max-width: 799px) {
  ul.menu {
    li:not(.item-online-planner) {
      display: none;
    }

    li.item-online-planner {
      display: inline-block !important;
    }
  }
}
</style>