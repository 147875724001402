<template>
  <div class="main-menu">
    <nav class="nav primary-menu">
      <div class="container">
        <div class="column">
          <div class="logo weight-400 inline-block">
            <a
              :href="$parent.config.website.base + '/'"
              class="no-decoration color-dark"
              ><img
                :src="$parent.config.website.base + '/assets/img/logo-arrow.svg'"
                height="40"
                alt="Cyclers"
              />
            </a>
          </div>
          <ul class="menu color-dark inline-block pull-right">
            <!-- 
            <li
              class="menu-item inline-block menu-item-bradius item-learn-more"
            >
              <a
                v-on:click="start()"
                href="./career"
                class="no-decoration color-primary bg-white"
                title="Plan route"
                >Career</a
              >
            </li>
            -->
            <li
              class="
                menu-item
                inline-block
                menu-item-bradius
                item-online-planner
              "
            >
              <a
                href="https://cyclers.app/plan"
                class="no-decoration color-primary bg-white border-primary"
                title="Plan route"
                >Online planner</a
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  data() {
    return {
      doc: document,
      win: window,
    };
  },
  methods: {
    gotoReviews() {
      let loc = this.doc.getElementById("reviews").offsetTop;
      loc = loc - 4 * this.win.innerHeight;
      console.log(loc);
      this.$parent.scrollTo(loc, 1500);
    },
    start() {
      const loc = this.doc.querySelector("#start").offsetTop;
      this.$parent.scrollTo(loc, 500);
      this.$parent.$refs.showcase.$refs.controls.gotoSlide(1);
    },
  },
};
</script>

<style scoped lang="scss">
nav {
  height: auto;
  background-color: white;
  box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.05);
  z-index: 100;

  .logo {
    padding: 21px 10px;
    font-size: 20px;
    position: relative;

    a {
      &:hover,
      &:active,
      &:focus {
        filter: none;
      }

      span {
        padding-left: 50px;
      }
      img {
        position: absolute;
        top: 11px;
      }
    }
    @media (max-width: 799px) {
      transform: scale(0.8);
      a img {
        top: 15px;
      }
    }
  }
}

.menu-item {
  font-size: 16px;

  a {
    padding: 10px 25px;
    margin: 10px 0px;
    line-height: 62px;
  }

  @media (max-width: 799px) {
    font-size: 14px;
    a {
      padding: 5px 15px;
      margin: 10px 0px;
      // line-height: 65px;
    }
  }
  @media (max-width: 639px) {
    &.item-online-planner {
      display: none;
    }
  }
  @media (max-width: 399px) {
    &.item-learn-more {
      display: none;
    }
  }
}

.menu-item-bradius {
  a {
    border-radius: 20px;
  }
}

.menu {
  overflow-x: auto;
  max-width: 100%;
  white-space: nowrap;
  @media (max-width: 1279px) {
    margin-right: 10px;
  }
}
</style>
