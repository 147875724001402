<template>
    <div class="super-container sportega" ref="container">
        <div class="first-half">
            <header>
                <MainMenu />
            </header>
        </div>

        <content>
            <div class="meta">
                <Breadcrumbs :crumbs="[{ title: 'Home', path: '/' }, { title: 'Sportega', path: `/sportega` }]" />
            </div>

            <section class="header">
                <img :src="'https://umotional.com/wp-content/uploads/2024/03/banner-' + lang + '.jpg'" alt="Banner" />
            </section>

            <section class="slide" data-nth="0">
                <div class="inner">
                    <p class="lead" v-html="$t('sportega.lead')">

                    </p>
                </div>
            </section>
            <section class="slide" data-nth="1">
                <div class="inner">
                    <h2 class="heading-2">{{ $t('sportega.sections.a.heading') }}</h2>
                    <div v-html="$t('sportega.sections.a.content') ">
                    </div>
                </div>
            </section>
            <section class="slide" data-nth="2">
                <div class="inner">
                    <h2 class="heading-2">{{ $t('sportega.sections.b.heading') }}</h2>
                    <div v-html="$t('sportega.sections.b.content') ">
                    </div>
                </div>
            </section>
            <section class="slide" data-nth="3">
                <div class="inner">
                    <h2 class="heading-2">{{ $t('sportega.sections.c.heading') }}</h2>
                    <div v-html="$t('sportega.sections.c.content') ">
                    </div>
                </div>
            </section>
            <section class="slide" data-nth="4">
                <div class="inner">
                    <h2 class="heading-2">{{ $t('sportega.sections.d.heading') }}</h2>
                    <div v-html="$t('sportega.sections.d.content') ">
                    </div>
                </div>
            </section>
            <section class="slide" data-nth="5">
                <div class="inner">
                    <h2 class="heading-2">{{ $t('sportega.sections.e.heading') }}</h2>
                    <div v-html="$t('sportega.sections.e.content') ">
                    </div>
                </div>
            </section>
        </content>

        <footer>
            <FooterLinks />
        </footer>

        <GetApp ref="getApp" />
    </div>
</template>

<script>
import { config } from "@/config";
import MainMenu from "@/components/MainMenu.vue";
import FooterLinks from "@/components/FooterLinks.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
    name: "SportegaLanding",
    components: {
        MainMenu,
        FooterLinks,
        Breadcrumbs
    },
    data() {
        return {
            config: config,
            base: config.website.base,
            defaultLang: 'cz',
        };
    },
    i18n: {
        messages: {
            cz: {
                sportega: {
                    lead: `333…222…111…cyklo sezóna brzy startuje a my pro tebe máme speciální dárek. Díky Sportega můžeš nyní vyzkoušet všechny funkce unikátní AI cyklonavigace Cyclers na 30 dní zcela zdarma.`,
                    sections: {
                        a: {
                            heading: `V čem je cyklonavigace Cyclers unikátní? `,
                            content: `<p>Díky AI ti Cyclers navrhnou zajímavé trasy přizpůsobené typu tvého kola a tvému jízdnímu stylu,. Trasy,  o kterých netušíš, že ve tvém okolí vůbec existují!. Cyclers přitom umí nabídnout jak odladěné trasy z místa A do místa B, tak perfektní okruhy délky, kterou chceš. V Cyclers Plus dostaneš i unikátní Battery saver a Lite mode, díky kterému tě appka při navigaci bude méně rozptylovat a baterka ti vydrží déle.</p>`,
                        },
                        b: {
                            heading: `Jak se zapojit do soutěže:`,
                            content: `
                            <ul>
                                <li>Stáhni si aplikaci Cyclers na <a href="https://play.google.com/store/apps/details?id=com.umotional.bikeapp&hl=cs&gl=cs&referrer=utm_source%3Dcomarketing%26utm_medium%3Dlandingpage%26utm_campaign%Sportega">Google Play</a> nebo <a href="https://apps.apple.com/cz/app/cyclers-bike-navigation-map/id1213867271">App Store</a>.</li>
                                <li>Aktivuj Cyclers Plus na 30 dní zdarma pomocí aktivačního kódu “333222111”. Ćas máš jen do 31.3. 2024!</li>
                                <li>Najezdi se Cyclers do 30.4.2024 alespoň 100 km - tím se automaticky staneš účastníkem slosování</li>
                                <li>Výběr výherců: Výherci budou vylosováni z účastníků, kteří splní všechny podmínky. Losování se uskuteční první týden po ukončení soutěže.</li>
                            </ul>
                            `,
                        },
                        c: {
                            heading: `Proces aktivace 30 dní zdarma pomocí kódu “333222111”:`,
                            content: `
                            <h3>Android zařízení</h3>
                            <ol>
                                <li>Zobrazte sekci “Profil”</li>
                                <li>Zde naleznete sekci “Mám Cyclers Plus promo kód”</li>
                                <li>Promo kód vložte a záčkněte “Potvrdit”</li>
                            </ol>
                            <h3>Zařízení s iOS:</h3>
                            <ol>
                                <li>Zobrazte sekci “Profil”</li>
                                <li>Zde naleznete sekci “Ziskej Cyclers Plus”</li>
                                <li>Klikněte níže na link “Zobrazit více možností”</li>
                                <li>Klikněte na tlačítko “Uplatnit kód”</li>
                                <li>Promo kód vložte a záčkněte “Potvrdit”</li>
                            </ol>
                            `,
                        },
                        d: {
                            heading: `Podmínky soutěže:`,
                            content: `
                            <ul>
                                <li>Organizátor soutěže: Soutěž je pořádána společností Umotional s.r.o., ve spolupráci se Sportega s.r.o.</li>
                                <li>Účast v soutěži: Účastnit se mohou osoby s registrovanou emailovou adresou u společnosti Sportega s.r.o., kteří si stáhnou aplikaci Cyclers na Google Play nebo iOS, aktivují placenou verzi Cyclers Plus pomocí kódu “333222111” a do 30. dubna 2024 najedou s aplikací Cyclers alespoň 100 km.</li>
                                <li>Období soutěže: Soutěž probíhá od vyhlášení do 30. dubna 2024. Aktivační kód je možné použít do konce března 2024.</li>
                                <li>Ceny: Tři výherci budou náhodně vylosováni a získají roční licenci Cyclers Plus zdarma. Výherci budou informováni e-mailem zaslaným e-maliovou adresou , pod kterou jsou registrování v aplikaci Cyclers. nebo telefonu.</li>
                            </ul>
                            `,
                        },
                        e: {
                            heading: `Obecné podmínky:`,
                            content: `
                            <ul>
                                <li>Každý účastník může soutěžit pouze jednou.</li>
                                <li>Ceny nejsou převoditelné na jiné osoby a nelze je vyměnit za hotovost.</li>
                                <li>Organizátor si vyhrazuje právo na změnu pravidel nebo ukončení soutěže v případě nečekaných okolností.</li>
                            </ul>
                            `,
                        },
                    },
                }
            },
            en: {
                sportega: {
                    lead: `333...222...111... The cycling season is about to start and we have a special gift for you. Thanks to Sportega, you can now try all the features of Cyclers, the  unique AI bike navigation, for 30 days completely free.`,
                    sections: {
                        a: {
                            heading: `What makes the Cyclers bike navigation unique?`,
                            content: `<p>Thanks to AI, Cyclers can suggest interesting routes tailored to your bike type and your riding style, routes you didn’t even know existed in your area. Cyclers excels both in suggesting fine-tuned A-to-B routes and in planning those perfect circuits of your desired distance. With Cyclers Plus, you also gain access to a unique Battery Saver and Lite Mode, which make the app less distracting during navigation and help your battery last longer.</p>`,
                        },
                        b: {
                            heading: `How to participate:`,
                            content: `
                            <ul>
                                <li>Download the Cyclers app from <a href="https://play.google.com/store/apps/details?id=com.umotional.bikeapp&hl=en&gl=US&referrer=utm_source%3Dcomarketing%26utm_medium%3Dlandingpage%26utm_campaign%Sportega">Google Play</a> or the <a href="https://apps.apple.com/cz/app/cyclers-bike-navigation-map/id1213867271">App Store</a>.</li>
                                <li>Activate Cyclers Plus for 30 days for free using the "333222111" activation code. You only have until March 31, 2024, to activate.</li>
                                <li>Ride at least 100 km with Cyclers by April 30, 2024 - this will automatically enroll you in the draw.</li>
                                <li>Selection of winners: Winners will be drawn from participants who have met all conditions. The draw will take place in the first week of May.</li>
                            </ul>
                            `,
                        },
                        c: {
                            heading: `Activation process for 30 days free using the code "333222111":`,
                            content: `
                            <h3>Android devices:</h3>
                            <ol>
                                <li>Go to the “Profile” section.</li>
                                <li>Here, you will find the section “I have a Cyclers Plus promo code.”</li>
                                <li>Enter the promo code and select “Confirm.”</li>
                            </ol>
                            <h3>iOS devices:</h3>
                            <ol>
                                <li>Go to the “Profile” section.</li>
                                <li>Here, you will find the section “Get Cyclers Plus.”</li>
                                <li>Click on the link “Show more options” below.</li>
                                <li>Click on the “Redeem code” button.</li>
                                <li>Enter the promo code and select “Confirm.”</li>
                            </ol>
                            `,
                        },
                        d: {
                            heading: `Terms and Conditions of the Competition`,
                            content: `
                            <ul>
                                <li>Organizer of the competition: The competition is organized by Umotional s.r.o., in cooperation with Sportega s.r.o.</li>
                                <li>Participation in the competition: Participation is open to individuals with a registered email address at Sportega s.r.o. Participants must download the Cyclers app from Google Play or the App Store, activate the paid version of Cyclers Plus using the code "333222111," and ride at least 100 km with the Cyclers app by April 30, 2024.</li>
                                <li>Competition period: The competition runs from the announcement date until April 30, 2024. The activation code can be used until the end of March 2024.</li>
                                <li>Prizes: Three winners will be randomly selected and will receive a free one-year Cyclers Plus license. Winners will be informed via email sent to their registered email address in Cyclers.</li>
                            </ul>
                            `,
                        },
                        e: {
                            heading: `General Terms:`,
                            content: `
                            <ul>
                                <li>Each participant can compete only once.</li>
                                <li>Prizes are not transferable to other persons and cannot be exchanged for cash.</li>
                                <li>The organizer reserves the right to change the rules or terminate the competition in case of unexpected circumstances.</li>
                            </ul>
                            `,
                        },
                    },
                }
            },
            fr: {
                sportega: {
                    lead: `333...222...111... La saison de cyclisme est sur le point de commencer et nous avons un cadeau spécial pour vous. Grâce à Sportega, vous pouvez maintenant essayer toutes les fonctionnalités de Cyclers, la navigation à vélo unique basée sur l'IA, pendant 30 jours totalement gratuitement.`,
                    sections: {
                        a: {
                            heading: `Qu'est-ce qui rend la navigation à vélo Cyclers unique?`,
                            content: `<p>Grâce à l'IA, Cyclers peut suggérer des itinéraires intéressants adaptés à votre type de vélo et à votre style de conduite, des itinéraires que vous ne saviez même pas existants dans votre région. Cyclers excelle tant dans la suggestion d'itinéraires précis de point A à point B que dans la planification de ces circuits parfaits de la distance souhaitée. Avec Cyclers Plus, vous avez également accès à un mode économiseur de batterie et un mode Lite uniques, qui rendent l'application moins distrayante pendant la navigation et aident à prolonger la durée de la batterie.</p>`,
                        },
                        b: {
                            heading: `Comment participer:`,
                            content: `
                            <ul>
                                <li>Téléchargez l'application Cyclers depuis <a href="https://play.google.com/store/apps/details?id=com.umotional.bikeapp&hl=fr&gl=fr&referrer=utm_source%3Dcomarketing%26utm_medium%3Dlandingpage%26utm_campaign%Sportega">Google Play</a> ou <a href="https://apps.apple.com/cz/app/cyclers-bike-navigation-map/id1213867271">App Store</a>.</li>
                                <li>Activez Cyclers Plus pendant 30 jours gratuitement en utilisant le code d'activation "333222111". Vous avez jusqu'au 31 mars 2024 pour activer.</li>
                                <li>Parcourez au moins 100 km avec Cyclers avant le 30 avril 2024 - cela vous inscrira automatiquement au tirage au sort.</li>
                                <li>Sélection des gagnants: Les gagnants seront tirés au sort parmi les participants ayant rempli toutes les conditions. Le tirage aura lieu dans la première semaine de mai.</li>
                            </ul>
                            `,
                        },
                        c: {
                            heading: `Processus d'activation pour 30 jours gratuits en utilisant le code "333222111":`,
                            content: `
                            <h3>Appareils Android:</h3>
                            <ol>
                                <li>Rendez-vous dans la section "Profil"</li>
                                <li>Ici, vous trouverez la section "J'ai un code promo Cyclers Plus."</li>
                                <li>Entrez le code promo et sélectionnez "Confirmer."</li>
                            </ol>
                            <h3>Appareils iOS:</h3>
                            <ol>
                                <li>Rendez-vous dans la section "Profil".</li>
                                <li>Ici, vous trouverez la section "Obtenir Cyclers Plus."</li>
                                <li>Cliquez sur le lien "Afficher plus d'options" ci-dessous.</li>
                                <li>Cliquez sur le bouton "Utiliser le code".</li>
                                <li>Entrez le code promo et sélectionnez "Confirmer."</li>
                            </ol>
                            `,
                        },
                        d: {
                            heading: `Conditions générales de la compétitio:`,
                            content: `
                            <ul>
                                <li>Organisateur de la compétition: La compétition est organisée par Umotional s.r.o., en coopération avec Sportega s.r.o.</li>
                                <li>Participation à la compétition : La participation est ouverte aux individus possédant une adresse e-mail enregistrée chez Sportega s.r.o. Les participants doivent télécharger l'application Cyclers depuis Google Play ou l'App Store, activer la version payante de Cyclers Plus en utilisant le code "333222111" et parcourir au moins 100 km avec l'application Cyclers avant le 30 avril 2024.</li>
                                <li>Période de la compétition : La compétition se déroule de la date d'annonce jusqu'au 30 avril 2024. Le code d'activation peut être utilisé jusqu'à fin mars 2024.</li>
                                <li>Prix : Trois gagnants seront sélectionnés au hasard et recevront une licence Cyclers Plus gratuite pour un an. Les gagnants seront informés par e-mail envoyé à leur adresse enregistrée dans Cyclers.</li>
                            </ul>
                            `,
                        },
                        e: {
                            heading: `Conditions générales:`,
                            content: `
                            <ul>
                                <li>Chaque participant ne peut concourir qu'une seule fois.</li>
                                <li>Les prix ne sont pas transférables à d'autres personnes et ne peuvent pas être échangés contre de l'argent.</li>
                                <li>L'organisateur se réserve le droit de modifier les règles ou de mettre fin à la compétition en cas de circonstances imprévues</li>
                            </ul>
                            `,
                        },
                    },
                }
            },
            de: {
                sportega: {
                    lead: `333...222...111... Die Radsportsaison steht kurz bevor, und wir haben ein besonderes Geschenk für Sie. Dank Sportega können Sie jetzt alle Funktionen von Cyclers, der einzigartigen KI-gestützten Fahrradnavigation, 30 Tage lang völlig kostenlos testen.`,
                    sections: {
                        a: {
                            heading: `Was macht die Fahrradnavigation von Cyclers einzigartig?`,
                            content: `<p>Dank KI kann Cyclers interessante Routen vorschlagen, die auf Ihren Fahrradtyp und Ihren Fahrstil zugeschnitten sind, Routen, von denen Sie nicht einmal wussten, dass sie in Ihrer Region existieren. Cyclers ist nicht nur bei der Empfehlung von präzisen Punkt-zu-Punkt-Routen hervorragend, sondern auch bei der Planung jener perfekten Rundkurse Ihrer gewünschten Distanz. Mit Cyclers Plus erhalten Sie zudem Zugang zu einem einzigartigen Batteriesparmodus und einem Lite-Modus, die die App während der Navigation weniger ablenkend machen und helfen, die Batterielaufzeit zu verlängern.</p>`,
                        },
                        b: {
                            heading: `So nehmen Sie teil:`,
                            content: `
                            <ul>
                                <li>Laden Sie die Cyclers-App aus dem <a href="https://play.google.com/store/apps/details?id=com.umotional.bikeapp&hl=de&gl=de&referrer=utm_source%3Dcomarketing%26utm_medium%3Dlandingpage%26utm_campaign%Sportega">Google Play</a> Store oder dem <a href="https://apps.apple.com/cz/app/cyclers-bike-navigation-map/id1213867271">App Store</a> herunter.</li>
                                <li>Aktivieren Sie Cyclers Plus 30 Tage lang kostenlos mit dem Aktivierungscode "333222111". Sie haben bis zum 31. März 2024 Zeit, diesen zu aktivieren.</li>
                                <li>Fahren Sie bis zum 30. April 2024 mindestens 100 km mit Cyclers - dadurch nehmen Sie automatisch an der Verlosung teil.</li>
                                <li>Auswahl der Gewinner: Die Gewinner werden aus den Teilnehmern ausgelost, die alle Bedingungen erfüllt haben. Die Ziehung findet in der ersten Maiwoche statt.</li>
                            </ul>
                            `,
                        },
                        c: {
                            heading: `Aktivierungsprozess für 30 Tage kostenlos mit dem Code "333222111":`,
                            content: `
                            <h3>Android-Geräte:</h3>
                            <ol>
                                <li>Gehen Sie zum Abschnitt „Profil“.</li>
                                <li>Hier finden Sie den Abschnitt „Ich habe einen Cyclers Plus Promo-Code“.</li>
                                <li>Geben Sie den Promo-Code ein und wählen Sie „Bestätigen“.</li>
                            </ol>
                            <h3>iOS-Geräte:</h3>
                            <ol>
                                <li>Gehen Sie zum Abschnitt „Profil“.</li>
                                <li>Hier finden Sie den Abschnitt „Cyclers Plus erhalten“.</li>
                                <li>Klicken Sie unten auf den Link „Mehr Optionen anzeigen“.</li>
                                <li>Klicken Sie auf die Schaltfläche „Code einlösen“.</li>
                                <li>Geben Sie den Promo-Code ein und wählen Sie „Bestätigen“.</li>
                            </ol>
                            `,
                        },
                        d: {
                            heading: `Teilnahmebedingungen des Wettbewerbs:`,
                            content: `
                            <ul>
                                <li>Veranstalter des Wettbewerbs: Der Wettbewerb wird von Umotional s.r.o. in Zusammenarbeit mit Sportega s.r.o. organisiert.</li>
                                <li>Teilnahme am Wettbewerb: Die Teilnahme steht Personen mit einer bei Sportega s.r.o. registrierten E-Mail-Adresse offen. Die Teilnehmer müssen die Cyclers-App aus dem Google Play Store oder dem App Store herunterladen, die kostenpflichtige Version von Cyclers Plus mit dem Code "333222111" aktivieren und bis zum 30. April 2024 mindestens 100 km mit der Cyclers-App fahren.</li>
                                <li>Wettbewerbszeitraum: Der Wettbewerb läuft von dem Ankündigungsdatum bis zum 30. April 2024. Der Aktivierungscode kann bis Ende März 2024 verwendet werden.</li>
                                <li>Preise: Drei Gewinner werden zufällig ausgewählt und erhalten eine kostenlose einjährige Cyclers Plus-Lizenz. Die Gewinner werden per E-Mail an ihre bei Cyclers registrierte Adresse benachrichtigt.</li>
                            </ul>
                            `,
                        },
                        e: {
                            heading: `Allgemeine Bedingungen:`,
                            content: `
                            <ul>
                                <li>Jeder Teilnehmer kann nur einmal teilnehmen.</li>
                                <li>Die Preise sind nicht auf andere Personen übertragbar und können nicht gegen Bargeld eingetauscht werden.</li>
                                <li>Der Veranstalter behält sich das Recht vor, die Regeln zu ändern oder den Wettbewerb im Falle unvorhergesehener Umstände zu beenden.</li>
                            </ul>
                            `,
                        },
                    },
                }
            },
            sk: {
                sportega: {
                    lead: `333…222…111…cyklo sezóna čoskoro štartuje a my pre teba máme špeciálny darček. Vďaka Sportega môžeš teraz vyskúšať všetky funkcie unikátnej AI cyklonavigácie Cyclers na 30 dní úplne zadarmo.`,
                    sections: {
                        a: {
                            heading: `V čom je cyklonavigácia Cyclers unikátna?`,
                            content: `<p>Vďaka AI ti Cyclers navrhnú zaujímavé trasy prispôsobené typu tvojho bicykla a tvojmu jazdnému štýlu. Trasy, o ktorých netušíš, že v tvojom okolí vôbec existujú! Cyclers pritom vie ponúknuť ako odladené trasy z miesta A do miesta B, tak aj perfektné okruhy dĺžky, ktorú chceš. V Cyclers Plus dostaneš aj unikátny Battery saver a Lite mode, vďaka ktorému ťa appka pri navigácii bude menej rozptyľovať a baterka ti vydrží dlhšie.</p>`,
                        },
                        b: {
                            heading: `Ako sa zapojiť do súťaže:`,
                            content: `
                            <ul>
                                <li>Stiahni si aplikáciu Cyclers na <a href="https://play.google.com/store/apps/details?id=com.umotional.bikeapp&hl=sk&gl=sk&referrer=utm_source%3Dcomarketing%26utm_medium%3Dlandingpage%26utm_campaign%Sportega">Google Play</a> alebo <a href="https://apps.apple.com/cz/app/cyclers-bike-navigation-map/id1213867271">App Store</a>.</li>
                                <li>Aktivuj Cyclers Plus na 30 dní zadarmo pomocou aktivačného kódu “333222111”. Ćas máš len do 31.3. 2024!</li>
                                <li>Najazdi sa Cyclers do 30.4.2024 aspoň 100 km - tým sa automaticky staneš účastníkom žrebovania</li>
                                <li>Výber výhercov: Výhercovia budú vyžrebovaní z účastníkov, ktorí splnia všetky podmienky. Žrebovanie sa uskutoční prvý týždeň po ukončení súťaže.</li>
                            </ul>
                            `,
                        },
                        c: {
                            heading: `Proces aktivácie 30 dní zadarmo pomocou kódu “333222111”:`,
                            content: `
                            <h3>Android zariadenia</h3>
                            <ol>
                                <li>Zobrazte sekciu “Profil”</li>
                                <li>Tu nájdete sekciu “Mám Cyclers Plus promo kód”</li>
                                <li>Promo kód vložte a začknite “Potvrdiť”</li>
                            </ol>
                            <h3>Zariadenia s iOS:</h3>
                            <ol>
                                <li>Zobrazte sekciu “Profil”</li>
                                <li>Tu nájdete sekciu "Získaj Cyclers Plus"</li>
                                <li>Kliknite nižšie na link “Zobraziť viac možností”</li>
                                <li>Kliknite na tlačidlo “Uplatniť kód”</li>
                                <li>Promo kód vložte a začknite “Potvrdiť”</li>
                            </ol>
                            `,
                        },
                        d: {
                            heading: `Podmienky súťaže:`,
                            content: `
                            <ul>
                                <li>Organizátor súťaže: Súťaž je organizovaná spoločnosťou Umotional s.r.o., v spolupráci so Sportega s.r.o.</li>
                                <li>Účasť v súťaži: Zúčastniť sa môžu osoby s registrovanou emailovou adresou v spoločnosti Sportega s.r.o., ktorí si stiahnu aplikáciu Cyclers na Google Play alebo iOS, aktivujú platenú verziu Cyclers Plus pomocou kódu “333222111” a do 30. apríla 2024 nájdu s aplikáciou Cyclers km.</li>
                                <li>Obdobie súťaže: Súťaž prebieha od vyhlásenia do 30. apríla 2024. Aktivačný kód je možné použiť do konca marca 2024.</li>
                                <li>Ceny: Traja výhercovia budú náhodne vyžrebovaní a získajú ročnú licenciu Cyclers Plus zadarmo. Výhercovia budú informovaní e-mailom zaslaným e-maliovou adresou , pod ktorou sú registrovaní v aplikácii Cyclers. alebo telefónu.</li>
                            </ul>
                            `,
                        },
                        e: {
                            heading: `Všeobecné podmienky:`,
                            content: `
                            <ul>
                                <li>Každý účastník môže súťažiť iba raz.</li>
                                <li>Ceny nie sú prevoditeľné na iné osoby a nemožno ich vymeniť za hotovosť.</li>
                                <li>Organizátor si vyhradzuje právo na zmenu pravidiel alebo ukončenie súťaže v prípade nečakaných okolností.</li>
                            </ul>
                            `,
                        },
                    },
                }
            },
        },
    },
    computed: {
        lang() {
            return this.$route.params.lang ? this.$route.params.lang : this.defaultLang;
        },
    },
    mounted() {
        this.$root.$i18n.locale = this.lang;
    },
    methods: {

    },
};
</script>

<style scoped lang="scss">
@import "@/scss/reset.scss";
@import "@/scss/vars.scss";
@import "@/scss/layout.scss";
@import "@/scss/common.scss";
@import "@/scss/conditionals.scss";
@import "@/scss/lines.scss";
@import "@/scss/opacity.scss";
@import "@/scss/colors.scss";
@import "@/scss/typography.scss";
@import "@/scss/forms.scss";
@import "@/scss/animations.scss";
@import "@/scss/app.scss";
</style>

<style lang="scss">
@import "@/scss/sportega.scss";

.super-container.sportega {
    .meta {
        justify-content: center;
        margin-bottom: 0;
    }

    .header {
        a {
            width: 100%;
        }

        height: auto;
    }

    content {
        p.lead {
            font-weight: 500;
        }
    }
}


@media (max-width: 1023px) {
    .super-container.sportega {
        content {
            margin-left: 10px;
            margin-right: 10px;

            .heading-1 {
                font-size: 30px;
                line-height: 22.44px;
            }

            .heading-2 {
                font-size: 27px;
                line-height: 32px;
                margin-top: 10px;
            }

            p {
                font-size: 19px;
                line-height: 29px;
            }

            ul,
            ol {
                font-size: 19px;
                line-height: 35px;
            }
        }
    }

}
</style>
