<template>
  <div class="footer-links" id="footer">
    <div class="inner">
      <div class="container narrow">
        <div class="large-column">
          <div class="cols pure-g">
            <div class="one-col col-qr pure-u-lg-1-4">
              <div class="heading">Download</div>
              <img width="85" height="88" alt="QR Code" class="qr" :src="base + '/assets/img/qr.png'" />
            </div>
            <div class="one-col pure-u-lg-1-4">
              <div>
                <div class="links stores">
                  <a
                    title="Online planner"
                    class="
                      badge badge-cyclers
                      no-decoration
                      inline-block
                      margin-auto
                    "
                    href="https://cyclers.app/plan"
                  >
                    <img
                      height="40"
                      width="134"
                      :src="base + '/assets/img/online-planner.png'"
                      alt="Online planner"
                    />
                  </a>
                  <br />
                  <a
                    title="Download for Android"
                    class="
                      badge badge-google
                      no-decoration
                      inline-block
                      margin-auto
                    "
                    :href="
                      'https://play.google.com/store/apps/details?id=' +
                      identifiers.googlePlay +
                      '&hl=en'
                    "
                  >
                    <img
                      height="40"
                      width="134"
                      :src="base + '/assets/img/googleplay-badge-en.png'"
                      alt="Google Play Store"
                    />
                  </a>
                  <br />
                  <a
                    title="Download for iOS"
                    class="
                      badge badge-apple
                      no-decoration
                      inline-block
                      margin-auto
                    "
                    :href="
                      'https://apps.apple.com/cz/app/cyclers-bike-map-navigation/id' +
                      identifiers.appStore
                    "
                  >
                    <img
                      height="40"
                      width="135"
                      :src="base + '/assets/img/appstore-badge-en.svg'"
                      alt="Apple AppStore"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="one-col pure-u-lg-1-4">
              <div>
                <div class="heading">Learn more</div>
                <div class="links">
                  <a class="link no-decoration" href="https://cyclers.app/places"> Cycling destinations </a>
                  <a class="link no-decoration" href="https://cyclers.app/support/"> Knowledge base </a>
                  <a class="link no-decoration" href="https://cyclers.app/terms/"> Terms of use </a>
                  <a class="link no-decoration" href="https://cyclers.app/privacy/"> Privacy policy </a>
                </div>
              </div>
            </div>
            <div class="one-col pure-u-lg-1-4">
              <div>
                <div class="heading">Follow us</div>
                <div class="links social">
                  <a href="https://www.facebook.com/CyclersApp/" rel="nofollow" target="_blank">
                    <img
                      :src="base + '/assets/img/facebook-svgrepo-com.svg'"
                      alt="Facebook"
                      class="social"
                    />
                  </a>
                  <a href="https://www.instagram.com/cyclersapp/" rel="nofollow" target="_blank">
                    <img
                      :src="base + '/assets/img/instagram-svgrepo-com.svg'"
                      alt="Instagram"
                      class="social"
                    />
                  </a>
                  <!-- <a href="#">
                    <img
                      :src="base + '/assets/img/youtube-svgrepo-com.svg'"
                      alt="Youtube"
                      class="social"
                    />
                  </a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterNav",
  props: {},
  data() {
    return {
      base: this.$parent.config.website.base,
      identifiers: {
        googlePlay: this.$parent.config.identifiers.googlePlay,
        appStore: this.$parent.config.identifiers.appStore,
      },
    };
  },
};
</script>

<style scoped lang="scss">
.footer-links {
  background-color: #277a3e;

  .inner {
    .container {
      &.narrow {
        // max-width: 1100px;

        @media (min-width: 1400px) {
          padding-left: 170px;
        }
      }
    }

    background: url("/public/assets/img/mapbg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 280px;

    .qr {
      width: 85px;
      padding: 5px;
      border-radius: 5px;
      background-color: white;
    }

    @media (max-width: 639px) {
      .col-qr {
        display: none;
      }
    }

    .heading {
      font-size: 20px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;
      color: var(--color-white);
      margin: 70px 0 20px 0;
    }
    .links {
      &.social {
        display: flex;
        align-content: center;

        @media (max-width: 639px) {
          justify-content: center;

          a img {
            margin: 0 !important;
            padding: 3px 5px;
          }
        }
      }
      &.stores {
        padding-top: 70px;
      }
      .link {
        color: var(--color-white);
        display: block;
        line-height: 30px;
      }
      .btn {
        background: var(--color-white);
        color: var(--color-primary);
        border-radius: 56.56px;
        font-weight: 500;
        font-size: 14.5729px;
        line-height: 13px;
        padding: 15px 20px;
        text-decoration: none;
        display: inline-block;

        &:hover {
          filter: brightness(90%);
        }
      }
      .badge {
        display: inline-block;
        margin-bottom: 5px;
        img {
          // display: block;
          // height: 30px;
          // widows: auto;
        }
      }
      img.social {
        width: 26px;
        height: 26px;
        margin: 5px 10px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }
  }
  @media (max-width: 1399px) {
    .column {
    }
    .cols {
      display: flex;
      justify-content: center;

      .one-col {
        width: auto;
        padding: 0 25px;
      }
    }
  }
  @media (max-width: 639px) {
    .one-col {
      width: 100% !important;
      text-align: center;
      .heading {
        margin: 30px 0 10px 0;
      }
      .links {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
