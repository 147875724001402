<template>
    <div class="super-container" ref="container">
        <div class="first-half">
            <header>
                <MainMenu />
            </header>
        </div>

        <content>
            <template v-if="data">
                <div class="meta">
                    <Breadcrumbs :crumbs="generateCrumbs()" />
                    <ShareBtns />
                </div>

                <section class="header">
                    <img :src="`${config.website.api}/v1/place/${lang}/${placeId}/image`" alt="Cyclist"
                        onerror="this.onerror = null; this.src = data.imageUrl" />
                    <div class="wave" />
                    <h1 class="heading-1">
                        {{ data.name }}
                    </h1>
                </section>

                <section class="slide" data-nth="1">
                    <div class="inner">
                        <p v-html="data.shortDescription">
                        </p>
                    </div>
                </section>

                <section class="slide gray" v-if="data.topChildRegions.length || data.topChildTouristAreas.length || data.topChildPOIs.length">
                    <div class="inner">
                        <h2 class="heading-2">{{ $t('destination.heading.popular', { name: data.name }) }}</h2>
                        <p v-html="$t('destination.description.popular', { name: data.name })">
                        </p>
                        <div class="boxes">
                            <div class="box" v-if="data.topChildRegions.length">
                                <h3 class="heading-box">{{ $t('destination.box.heading.recommendedRegions') }}</h3>
                                <ul>
                                    <li v-for="ref, i in data.topChildRegions" v-bind:key="i">
                                        <router-link :to="`/places/${lang}/${ref.slug}`">{{ ref.name }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="box" v-if="data.topChildTouristAreas.length">
                                <h3 class="heading-box">{{ $t('destination.box.heading.recommendedTouristAreas') }}</h3>
                                <ul>
                                    <li v-for="ref, i in data.topChildTouristAreas" v-bind:key="i">
                                        <router-link :to="`/places/${lang}/${ref.slug}`">{{ ref.name }}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="box" v-if="data.topChildPOIs.length">
                                <h3 class="heading-box">{{ $t('destination.box.heading.recommendedPois') }}</h3>
                                <ul>
                                    <li v-for="ref, i in data.topChildPOIs" v-bind:key="i">
                                        <router-link :to="`/places/${lang}/${ref.slug}`">{{ ref.name }}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="slide gray">
                    <div class="inner">
                        <h2 class="heading-2">{{ $t('destination.heading.looking', { name: data.name }) }}</h2>
                        <div class="map-wrapper" v-on:click.prevent="openPlanner()">
                            <Map ref="map" :center="[15.2608836, 49.4237088]" />
                        </div>
                        <div class="upsell-1">
                            <p>
                                {{ $t('destination.upsell.heading') }}
                            </p>
                            <div class="btns">
                                <a class="btn"
                                    :href="'https://apps.apple.com/cz/app/cyclers-bike-map-navigation/id' + config.identifiers.appStore + '?pt=118568913&ct=destination_pages_v1&mt=8'">
                                    <img src="/assets/img/appstore-badge-en.svg" alt="App Store" height="45" />
                                </a>
                                <a class="btn"
                                    :href="'https://play.google.com/store/apps/details?id=' + config.identifiers.googlePlay + '&hl=en&utm_source=destination_pages_v1'">
                                    <img src="/assets/img/googleplay-badge-en.png" alt="Play Store" height="45" />
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="slide gray">
                    <div class="inner">
                        <h2 class="heading-2">{{ $t('destination.heading.routes', { name: data.name }) }}</h2>
                        <p v-html="data.routesCharacteristicsDescription">

                        </p>
                        <div class="boxes graphics">
                            <div class="box">
                                <img src="/assets/img/destinations/icons/easy.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.cyclingNetworkLengthKm) }} km</div>
                                <div class="line2">{{ $t('destination.stats.suitableTotal') }}</div>
                            </div>
                            <div class="box">
                                <img src="/assets/img/destinations/icons/elevation.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ data.stats.minAltitudeM }} - {{ data.stats.maxAltitudeM }} m
                                </div>
                                <div class="line2">{{ $t('destination.stats.elevationDiff') }}</div>
                            </div>
                            <div class="break"></div>
                            <div class="box">
                                <img src="/assets/img/destinations/icons/tree--green.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.segmentsAlongGreenKm) }} km</div>
                                <div class="line2">{{ $t('destination.stats.inNature') }}</div>
                            </div>
                            <div class="box">
                                <img src="/assets/img/destinations/icons/swimlanes.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.segmentsAlongWaterKm) }} km</div>
                                <div class="line2">{{ $t('destination.stats.aroundWater') }}</div>
                            </div>
                            <div class="box">
                                <img src="/assets/img/destinations/icons/hills.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.segmentsWithSteepSlopeKm) }} km</div>
                                <div class="line2">{{ $t('destination.stats.onHills') }}</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="slide gray" style="z-index: 1; position: relative;">
                    <div class="inner">
                        <h2 class="heading-2">{{ $t('destination.heading.routes', { name: data.name }) }}</h2>
                        <p v-html="data.routesSuitabilityDescription">

                        </p>
                        <div class="boxes graphics">
                            <div class="box">
                                <img src="/assets/img/destinations/icons/mountain.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.segmentsSuitableForMountainBikingKm) }}
                                    km</div>
                                <div class="line2">{{ $t('destination.stats.forMTB') }}</div>
                            </div>
                            <div class="box">
                                <img src="/assets/img/destinations/icons/road.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.segmentsSuitableForRoadCyclingKm) }} km
                                </div>
                                <div class="line2">{{ $t('destination.stats.forRoad') }}</div>
                            </div>
                            <div class="box">
                                <img src="/assets/img/destinations/icons/gravel.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.segmentsSuitableForGravelRidingKm) }} km
                                </div>
                                <div class="line2">{{ $t('destination.stats.forGravel') }}</div>
                            </div>
                            <div class="break"></div>
                            <div class="box">
                                <img src="/assets/img/destinations/icons/e-biking.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.segmentsSuitableForEBikingKm) }} km</div>
                                <div class="line2">{{ $t('destination.stats.forEbike') }}</div>
                            </div>
                            <div class="box">
                                <img src="/assets/img/destinations/icons/safe.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.segmentsSuitableForSafeCyclingKm) }} km
                                </div>
                                <div class="line2">{{ $t('destination.stats.forSafe') }}</div>
                            </div>
                            <div class="box">
                                <img src="/assets/img/destinations/icons/easy.svg" alt="Icon" class="icon" />
                                <div class="line1 bold">{{ formatNumber(data.stats.segmentsSuitableForEasyCyclingKm) }} km
                                </div>
                                <div class="line2">{{ $t('destination.stats.forEasy') }}</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="slide full" style="z-index: 0; margin-top: 50px;">
                    <div class="wave" />
                </section>

                <section class="slide gray">
                    <div class="inner" style="padding: 100px 0;">
                        <h2 class="heading-2">{{ $t('destination.about.heading') }}</h2>
                        <p v-html="$t('destination.about.text')">

                        </p>

                        <div class="about">
                            <div class="left">
                                <img src="/assets/img/destinations/screens.svg" alt="Screenshots" height="306" />
                            </div>
                            <div class="right">
                                <p class="bold">
                                    {{ $t('destination.about.banner.right.line1') }}
                                </p>
                                <p>
                                    {{ $t('destination.about.banner.right.line2') }}
                                </p>
                                <div class="btns">
                                    <a class="btn"
                                        :href="'https://apps.apple.com/cz/app/cyclers-bike-map-navigation/id' + config.identifiers.appStore + '?pt=118568913&ct=destination_pages_v1&mt=8'">
                                        <img src="/assets/img/appstore-badge-en.svg" alt="App Store" height="45" />
                                    </a>
                                    <a class="btn"
                                        :href="'https://play.google.com/store/apps/details?id=' + config.identifiers.googlePlay + '&hl=en&utm_source=destination_pages_v1'">
                                        <img src="/assets/img/googleplay-badge-en.png" alt="Play Store" height="45" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </template>
            <div class="inner" v-else>
                <div class="loader">
                    {{ $t('loading') }}
                </div>
            </div>

        </content>

        <footer>
            <FooterLinks />
        </footer>

        <GetApp ref="getApp" />
    </div>
</template>

<script>
import { config } from "@/config";
import MainMenu from "@/components/MainMenu.vue";
import FooterLinks from "@/components/FooterLinks.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ShareBtns from "@/components/ShareBtns.vue";
import Map from "@/components/Map.vue";
import "mapbox-gl/dist/mapbox-gl.css"
import mapboxgl from "mapbox-gl";
import bbox from '@turf/bbox';

export default {
    name: "Destination",
    components: {
        MainMenu,
        FooterLinks,
        Breadcrumbs,
        ShareBtns,
        Map
    },
    data() {
        return {
            config: config,
            base: config.website.base,
            data: null,
        };
    },
    watch: {
        $route(to) {
            this.data = null;
            if (to.params.lang && to.params.slug) {
                this.init();
            } // end if
        }
    },
    computed: {
        lang() {
            return this.$route.params.lang;
        },
        placeId() {
            let slug = Array.isArray(this.$route.params.slug) ? this.$route.params.slug : [this.$route.params.slug];
            return slug[slug.length - 1].split("--").pop();
        },
        defaultPic() {
            return this.base + '/assets/img/destinations/cyclist_panorama.jpg';
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.$http.get(`${config.website.api}/v1/place/${this.lang}/${this.placeId}.json`).then((response) => {
                this.$root.$i18n.locale = this.lang;
                this.data = response.data;
                this.initMap();

                // FIXME zatim na hulvata
                document.title = document.title.replace('{{ title }}', this.data.name);
                document.title = document.title.replace('{{ suffix }}', this.$t('destination.meta.title.suffix'));
            });
        },
        initMap() {
            let i = 0;

            let mapMoved = false;
            let bounds = new mapboxgl.LngLatBounds();

            this.data.geojson.features.forEach((feature) => {
                feature.geometry.coordinates.forEach((coords) => {
                    if (coords.forEach) {
                        coords.forEach((coord) => {
                            bounds.extend(coord);
                        });
                    }
                });

                // TODO: HACK, fix this
                let interval = setInterval(() => {
                    if (this.$refs.map) {
                        clearInterval(interval);

                        let uid = `${i}_${Date.now()}`;

                        let map = this.$refs.map.getInstance();

                        switch (feature.geometry.type) {
                            case "Polygon":
                                map.addSource(`${feature.geometry.type}_${uid}`, {
                                    'type': 'geojson',
                                    'data': feature
                                });
                                map.addLayer({
                                    'id': `layer_${uid}`,
                                    'type': 'fill',
                                    'source': `${feature.geometry.type}_${uid}`,
                                    'layout': {
                                        'line-join': 'round',
                                        'line-cap': 'round'
                                    },
                                    'paint': {
                                        'fill-outline-color': 'rgb(39, 122, 62)',
                                        'fill-color': 'rgba(39, 122, 62, 0.25)',
                                        'fill-opacity': 1
                                    }
                                });

                                // FIXME: Possible race condition
                                if (!mapMoved) {
                                    mapMoved = true;
                                    map.fitBounds(bounds, {
                                        padding: 100
                                    });
                                }
                                break;

                            case "Point":
                                new mapboxgl.Marker()
                                    .setLngLat(feature.geometry.coordinates)
                                    .addTo(map);

                                if (!mapMoved) {
                                    mapMoved = true;
                                    map.setCenter(feature.geometry.coordinates);
                                    map.setZoom(12);
                                }
                                break;
                        }


                    }
                }, 1500);
                ++i;
            });



        },
        generateCrumbs() {
            let crumbs = [{ title: 'Home', path: '/' }, { title: 'Places', path: `/places` }];

            this.data.breadcrumbs.forEach((crumb) => {
                crumbs.push({
                    title: crumb.name,
                    path: `/places/${this.lang}/${crumb.url}`,
                });
            });

            // crumbs.push({
            //     title: this.data.name,
            //     path: `/places/${this.lang}/${this.data.slug}`,
            // });

            return crumbs;
        },
        formatNumber(num) {
            // Format number using commas for thousands, withour decimal places
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        openPlanner() {
            const boundingBox = bbox(this.data.geojson);
            let url = `${config.website.app}?bbox=${boundingBox.join(',')}`;
            window.open(url, '_blank');
        }
    },
};
</script>

<style scoped lang="scss">
@import "@/scss/reset.scss";
@import "@/scss/vars.scss";
@import "@/scss/layout.scss";
@import "@/scss/common.scss";
@import "@/scss/conditionals.scss";
@import "@/scss/lines.scss";
@import "@/scss/opacity.scss";
@import "@/scss/colors.scss";
@import "@/scss/typography.scss";
@import "@/scss/forms.scss";
@import "@/scss/animations.scss";
@import "@/scss/app.scss";
@import "@/scss/destinations.scss";
</style>

<style scoped lang="scss">
.map-wrapper {
    flex: 1;
    display: block;
    position: relative;
    cursor: pointer;
    
    &:hover {   
        filter: brightness(1.05);
    }

    * {
        pointer-events: none;
    }
}
</style>
