<template>
  <div class="super-container" ref="container">
    <div class="first-half">
      <header>
        <MainMenu />
      </header>
    </div>

    <section class="header">
      <img
        :src="base + '/assets/img/ambassador/team--full.png'"
        alt="Cyclers team"
      />
      <div class="gradient" />
    </section>

    <section class="slide" data-nth="1">
      <div class="inner">
        <h1 class="heading-1">
          Ambassador program
          <img
            :src="base + '/assets/img/ambassador/icon--community.svg'"
            class="ico"
            alt="Icon"
          />
          <span
            class="stroke"
            :style="{
              'background-image':
                'url(' + base + '/assets/img/ambassador/stroke.svg)',
              'background-size': 'contain',
              'background-repeat': 'no-repeat',
              'background-position': '50% 50%',
              height: '35px',
              width: 'calc(100% - 68px - 30px)',
              display: 'block',
              transform: 'scale(1, .9)',
            }"
          ></span>
        </h1>
        <p>
          Our team is committed to improving the cycling experience for casual
          riders and we are looking for like-minded individuals to become our
          ambassadors. Cyclers Ambassador is a representative who lives and
          breathes cycling, loves exploring new routes, and is passionate about
          enhancing the overall cycling experience. As an ambassador, you will
          represent and promote our app within your network and beyond.
        </p>
      </div>
    </section>

    <section class="slide gray custom-separator" data-nth="2">
      <div class="inner">
        <h2 class="heading-2">Who are we looking for?</h2>
        <ul>
          <li>Passionate user of our app</li>
          <li>Creative and communicative personality</li>
          <li>Cycling enthusiasts, casual riders, everyday explorers</li>
        </ul>

        <h2 class="heading-2">What will you do?</h2>
        <ul>
          <li>Spread the word about Cyclers online and in your community</li>
          <li>Help us improve Cyclers with your feedback</li>
          <li>Network with other Cyclers ambassadors</li>
          <li>Create content about Cyclers (photos, videos or articles)</li>
        </ul>
        <img
          class="picture"
          :src="base + '/assets/img/ambassador/slide02--picture.svg'"
          alt="Slide 02 - Picture"
        />
      </div>
      <img
        class="separator"
        :src="base + '/assets/img/ambassador/slide02--separator.svg'"
        alt="Slide 02 - Separator"
      />
    </section>

    <section class="slide" data-nth="3">
      <div class="inner">
        <h1 class="heading-1">
          The benefits
          <img
            :src="base + '/assets/img/ambassador/icon--benefits.svg'"
            class="ico"
            alt="Icon"
          />
          <span
            class="stroke"
            :style="{
              'background-image':
                'url(' + base + '/assets/img/ambassador/stroke.svg)',
              'background-size': 'contain',
              'background-repeat': 'no-repeat',
              'background-position': '50% 50%',
              height: '35px',
              width: 'calc(100% - 68px - 30px)',
              display: 'block',
              transform: 'scale(1, 1.5)',
            }"
          ></span>
        </h1>
        <div class="boxes">
          <div class="box">
            <h3 class="heading-box">Cyclers Plus</h3>
            <p class="p-box">
              Unlimited access to all our features with Cyclers Plus for you and
              your friends
            </p>
          </div>
          <div class="box">
            <h3 class="heading-box">Community</h3>
            <p class="p-box">
              Meetups with other ambassadors and the Cyclers team
            </p>
          </div>
          <div class="break"></div>
          <div class="box">
            <h3 class="heading-box">Influence</h3>
            <p class="p-box">
              Shape the future of our app with your feedback and insights
            </p>
          </div>
          <div class="box">
            <h3 class="heading-box">Early access</h3>
            <p class="p-box">
              Be the first to test our new features and updates
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="slide gray custom-separator on-top" data-nth="4">
      <img
        class="separator"
        :src="base + '/assets/img/ambassador/slide04--separator.svg'"
        alt="Slide 04 - Separator"
      />

      <div class="inner">
        <h1 class="heading-1">
          How it works
          <img
            :src="base + '/assets/img/ambassador/icon--faq.svg'"
            class="ico"
            alt="Icon"
          />
          <span
            class="stroke"
            :style="{
              'background-image':
                'url(' + base + '/assets/img/ambassador/stroke.svg)',
              'background-size': 'contain',
              'background-repeat': 'no-repeat',
              'background-position': '50% 50%',
              height: '35px',
              width: 'calc(100% - 68px - 30px)',
              display: 'block',
              transform: 'scale(1, 1.5)',
            }"
          ></span>
        </h1>
        <ol>
          <li>Spread the word about Cyclers online and in your community</li>
          <li>Help us improve Cyclers with your feedback</li>
          <li>Network with other Cyclers ambassadors</li>
          <li>Create content about Cyclers (photos, videos or articles)</li>
        </ol>
        <div class="text-center">
          <button class="btn">Apply</button>
        </div>
      </div>
    </section>

    <footer>
      <FooterLinks />
    </footer>

    <GetApp ref="getApp" />
  </div>
</template>

<script>
import { config } from "@/config";
import MainMenu from "@/components/MainMenu.vue";
import FooterLinks from "@/components/FooterLinks.vue";
import GetApp from "@/components/GetApp.vue";

export default {
  name: "Ambassador",
  components: {
    MainMenu,
    FooterLinks,
    GetApp,
  },
  data() {
    return {
      doc: document,
      config: config,
      base: config.website.base,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/scss/reset.scss";
@import "@/scss/vars.scss";
@import "@/scss/layout.scss";
@import "@/scss/common.scss";
@import "@/scss/conditionals.scss";
@import "@/scss/lines.scss";
@import "@/scss/opacity.scss";
@import "@/scss/colors.scss";
@import "@/scss/typography.scss";
@import "@/scss/forms.scss";
@import "@/scss/animations.scss";
@import "@/scss/ambassador.scss";
</style>

<style scoped lang="scss">
.header {
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 0;
  position: relative;
  img {
    width: 100%;
  }
  .gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    );
  }
}
.slide {
  color: var(--color-dark, #1f3b2f);
  font-variant-numeric: lining-nums proportional-nums;
  padding: 30px 0px;

  .inner {
    max-width: 1050px;
    margin: 0 auto;
    position: relative;
  }

  &.gray {
    background: linear-gradient(
      130deg,
      #dfdfdf4f 0%,
      rgba(223, 223, 223, 0) 100%
    );
  }

  &.custom-separator:not(.on-top) {
    padding-bottom: 0;
  }
  &.custom-separator.on-top {
    padding-top: 0;
  }

  .separator {
    width: 100%;
  }

  .heading-1 {
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 32.44px; /* 81.099% */
    position: relative;
    display: inline-block;
    margin-bottom: 35px;

    .ico {
      vertical-align: text-bottom;
      margin-left: 30px;
      width: 68px;
    }
  }
  .heading-2 {
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 32.44px; /* 101.374% */
    margin-top: 25px;
    margin-bottom: 50px;
  }
  p {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 141.667% */
    margin-top: 25px;
    margin-bottom: 50px;
  }
  ul,
  ol {
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 166.667% */
    margin-bottom: 75px;
  }
  ul {
    li {
      list-style-type: initial;
    }
  }
  ol {
    li {
      list-style-type: decimal;
    }
  }

  .btn {
    font-family: Poppins;
    border-radius: 30px;
    background: var(--color-primary-light);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    color: #fff;
    text-align: center;
    font-size: 26.01px;
    font-style: normal;
    font-weight: 500;
    line-height: 13.131px; /* 50.484% */
    text-transform: uppercase;
    padding: 20px 40px;
    border: 0;
    display: inline-block;
    margin: 25px 0 75px 0;

    &:hover {
      background: var(--color-primary);
    }
  }

  .boxes {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 60px 0 100px 0;

    .box {
      width: 240px;
      height: 120px;
      padding: 25px 50px;
      flex-shrink: 0;
      background: #fff;
      box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
      display: inline-block;
      margin: 20px;

      .heading-box {
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32.44px; /* 135.165% */
        margin-top: 5px;
      }
      .p-box {
        color: #657d76;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px; /* 150% */
        margin-top: 10px;
      }
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }
  }

  &[data-nth="1"] {
    padding-top: 0;
  }
  &[data-nth="2"] {
    .picture {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
  &[data-nth="4"] {
    .ico {
      width: 50px;
      vertical-align: text-top;
    }
  }
}
</style>