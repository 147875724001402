export const dictionary = {
    en: {
        loading: "Loading...",
        destination: {
            meta: {
                title: {
                    suffix: "Find the best cycling routes"
                }
            },
            heading: {
                popular: "The most popular destinations for cycling in {name}",
                looking: "Are you looking for the best cycling routes in {name}?",
                pick: "Choose, plan, and easily navigate your preferred routes in {name} using the Cycles app",
                routes: "An unlimited number of cycling routes in {name} available in a single app",
            },
            description: {
                popular: "Discover the limitless possibilities for cycling in {name} with the Cyclers app, which offers an expansive selection of routes. This innovative app empowers users to create and explore an endless array of paths, from the most popular routes to those which are undiscovered. Experience the freedom to tailor your cycling adventure in {name}, as the Cyclers app brings a world of endless cycling opportunities right at your fingertips."
            },
            box: {
                heading: {
                    recommendedRegions: "Recommended regions for cycling",
                    recommendedTouristAreas: "Recommended tourist areas for cycling",
                    recommendedPois: "Recommended Points of Interest",
                }
            },
            upsell: {
                heading: "The Cyclers app provides the most extensive selection of cycling routes worldwide. Download or open the app on your mobile device to discover the best cycling routes near you."
            },
            about: {
                heading: "About Cyclers",
                text: "Searching for new exciting cycling routes? Download or open the Cyclers app on your mobile device and discover the best paths nearby. With Cyclers, navigation is effortless, guiding you through scenic and safe routes tailored to your preferences. Cyclers app transforms your cycling experience with AI-powered route planning, intuitive maps, and personalized features.",
                banner: {
                    right: {
                        line1: "Find the best cycling routes with Cyclers App. ",
                        line2: "Download the app now.",
                    }
                }
            },
            stats: {
                suitableTotal: "sutitable for cycling",
                elevationDiff: "min - max elevation",
                inNature: "in nature",
                aroundWater: "around water",
                onHills: "in the hills",
                forMTB: "mountain biking",
                forRoad: "road cycling",
                forGravel: "gravel riding",
                forEbike: "e-biking",
                forSafe: "safe cycling",
                forEasy: "easy cycling",
            }
        }
    },
    "de": {
        "loading": "Laden...",
        "destination": {
            meta: {
                title: {
                    suffix: "Finde die besten Fahrradrouten"
                }
            },
            "heading": {
                "popular": "Die beliebtesten Ziele für Radtouren in {name}",
                "looking": "Suchen Sie nach den besten Radwegen in {name}?",
                "pick": "Wählen, planen und navigieren Sie einfach Ihre bevorzugten Routen in {name} mit der Cycles-App",
                "routes": "Eine unbegrenzte Anzahl von Radwegen in {name}, verfügbar in einer einzigen App"
            },
            "description": {
                "popular": "Entdecken Sie die grenzenlosen Möglichkeiten für Radtouren in {name} mit der Cyclers-App, die eine umfangreiche Auswahl an Routen bietet. Diese innovative App ermöglicht es den Nutzern, eine endlose Reihe von Wegen zu erstellen und zu erkunden, von den beliebtesten Routen bis hin zu denen, die noch unentdeckt sind. Erleben Sie die Freiheit, Ihr Radabenteuer in {name} nach Ihren Wünschen zu gestalten, da die Cyclers-App eine Welt voller endloser Radfahrmöglichkeiten direkt an Ihren Fingerspitzen bringt."
            },
            "box": {
                "heading": {
                    "recommendedRegions": "Empfohlene Regionen zum Radfahren",
                    "recommendedTouristAreas": "Empfohlene touristische Gebiete zum Radfahren",
                    "recommendedPois": "Empfohlene Sehenswürdigkeiten"
                }
            },
            "upsell": {
                "heading": "Die Cyclers-App bietet die umfangreichste Auswahl an Radwegen weltweit. Laden Sie die App herunter oder öffnen Sie sie auf Ihrem mobilen Gerät, um die besten Radwege in Ihrer Nähe zu entdecken."
            },
            "about": {
                "heading": "Über Cyclers",
                "text": "Suchen Sie nach neuen aufregenden Radwegen? Laden Sie die Cyclers-App auf Ihr mobiles Gerät herunter oder öffnen Sie sie und entdecken Sie die besten Wege in der Nähe. Mit Cyclers ist die Navigation mühelos und führt Sie durch malerische und sichere Routen, die auf Ihre Vorlieben zugeschnitten sind. Die Cyclers-App verwandelt Ihr Raderlebnis mit KI-gestützter Routenplanung, intuitiven Karten und personalisierten Funktionen.",
                "banner": {
                    "right": {
                        "line1": "Finden Sie die besten Radwege mit der Cyclers App.",
                        "line2": "Laden Sie die App jetzt herunter."
                    }
                }
            },
            "stats": {
                "suitableTotal": "für Radfahren geeignete Straßen",
                "elevationDiff": "min - max Höhenunterschied",
                "inNature": "in der Natur",
                "aroundWater": "um Wasser herum",
                "onHills": "auf Hügeln",
                "forMTB": "für MTB",
                "forRoad": "für Rennrad",
                "forGravel": "für Schotter",
                "forEbike": "für E-Bike",
                "forSafe": "von sicheren Straßen",
                "forEasy": "von leichten Wegen"
            }
        }
    },
    "cz": {
        "loading": "Načítám...",
        "destination": {
            meta: {
                title: {
                    suffix: "Objev nejlepší cyklistické trasy"
                }
            },
            "heading": {
                "popular": "Nejoblíbenější destinace pro cyklistiku v okolí {name}",
                "looking": "Hledáte nejlepší cyklistické trasy v okolí {name}?",
                "pick": "Vyberte, naplánujte a snadno se orientujte na vašich preferovaných trasách v okolí {name} pomocí aplikace Cycles",
                "routes": "Neomezený počet cyklistických tras v okolí {name} dostupných v jedné aplikaci"
            },
            "description": {
                "popular": "Objevte neomezené možnosti pro cyklistiku v okolí {name} s aplikací Cyclers, která nabízí rozsáhlý výběr tras. Tato inovativní aplikace umožňuje uživatelům vytvářet a prozkoumávat nekonečné množství cest, od nejpopulárnějších tras až po ty, které jsou neobjevené. Zažijte svobodu přizpůsobit si vaše cyklistické dobrodružství v okolí {name}, protože aplikace Cyclers přináší svět nekonečných cyklistických příležitostí přímo pod vaše prsty."
            },
            "box": {
                "heading": {
                    "recommendedRegions": "Doporučené regiony pro cyklistiku",
                    "recommendedTouristAreas": "Doporučené turistické oblasti pro cyklistiku",
                    "recommendedPois": "Doporučené body zájmu"
                }
            },
            "upsell": {
                "heading": "Aplikace Cyclers nabízí nejširší výběr cyklistických tras na světě. Stáhněte si aplikaci nebo ji otevřete na vašem mobilním zařízení a objevte nejlepší cyklistické trasy ve vašem okolí."
            },
            "about": {
                "heading": "O aplikaci Cyclers",
                "text": "Hledáte nové vzrušující cyklistické trasy? Stáhněte si aplikaci Cyclers na vaše mobilní zařízení nebo ji otevřete a objevte nejlepší cesty v okolí. S Cyclers je navigace snadná, vede vás malebnými a bezpečnými trasami přizpůsobenými vašim preferencím. Aplikace Cyclers promění váš cyklistický zážitek díky plánování tras podporovanému umělou inteligencí, intuitivním mapám a personalizovaným funkcím.",
                "banner": {
                    "right": {
                        "line1": "Najděte nejlepší cyklistické trasy s aplikací Cyclers.",
                        "line2": "Stáhněte si aplikaci nyní."
                    }
                }
            },
            "stats": {
                "suitableTotal": "silnice vhodné pro cyklistiku",
                "elevationDiff": "min - max převýšení",
                "inNature": "v přírodě",
                "aroundWater": "kolem vody",
                "onHills": "v kopcích",
                "forMTB": "terénní cyklistika",
                "forRoad": "silniční cyklistika",
                "forGravel": "jízda na gravelu",
                "forEbike": "jízda na elektrokole",
                "forSafe": "bezpečná jízda",
                "forEasy": "pohodová jízda"
            }
        },
    },
    "fr": {
        destination: {
            meta: {
                title: {
                    suffix: "Trouvez les meilleurs itinéraires cyclables"
                }
            },
        }
    }
}