<template>
  <div class="user-carousel" :class="['carousel--testimonials_' + id, type]">
    <div v-for="(card, i) in cards" v-bind:key="i">
      <div class="card relative noselect">
        <div class="line-10px"></div>
        <span class="card--picture">
          <img
            :src="card.picture"
            :alt="card.name"
            class="make-cirle"
            width="50"
            height="50"
          />
        </span>
        <div class="name-and-rating">
          <span class="card--name weight-600 relative">{{ card.name }}</span>
          <span class="card--rating">
            <img width="20" height="20"
              v-for="i in 4"
              v-bind:key="i"
              :src="base + '/assets/img/star.svg'"
              alt="Star"
            />
            <img width="20" height="20"
              v-if="5.0 == card.rating"
              :src="base + '/assets/img/star.svg'"
              alt="Star"
            />
            <img width="20" height="20" v-else :src="base + '/assets/img/star-half.svg'" alt="Star" />
          </span>
        </div>
        <!-- <img
          v-if="'appStore' == card.source"
          :src="base + '/assets/img/app-store.png'"
          width="25"
          alt="Store icon"
          class="card--store"
        />
        <img
          v-if="'googlePlay' == card.source"
          :src="base + '/assets/img/google-play.png'"
          width="30"
          alt="Store icon"
          class="card--store"
        /> -->
        <div class="line-20px"></div>
        <p
          class="card--text color-dark-alt weight-300"
          v-html="card.review"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCarousel",
  data() {
    return {
      base: this.$parent.$parent.config.website.base,
      libraries: {
        tinySlider:
          "https://cdnjs.cloudflare.com/ajax/libs/tiny-slider/2.9.2/min/tiny-slider.js",
      },
    };
  },
  props: ["id", "type", "cards"],
  mounted() {
    this.shuffleCards();

    const self = this;
    const head = document.head;

    const tinySlider = document.createElement("script");
    tinySlider.src = this.libraries.tinySlider;
    tinySlider.type = "application/javascript";
    tinySlider.onload = () => {
      self.initCarousel();
    };

    head.appendChild(tinySlider);
  },
  methods: {
    initCarousel() {
      let items = Math.floor(window.innerWidth / 350);
      // if (items > 3) items = 3;

      window.tns({
        container: ".carousel--testimonials_" + this.id,
        // mode: 'gallery',
        items: items,
        autoplay: true,
        controls: false,
        prevButton: false,
        nextButton: false,
        nav: false,
        speed: 1500,
        mouseDrag: true,
        slideBy: 1,
        autoplayTimeout: 5000,
        // edgePadding: 200,
        responsive: {
          300: {
            items: 1,
          },
          640: {
            items: 2,
          },
          700: {
            items: 2,
          },
          1000: {
            items: 3,
          },
          1367: {
            items: 4,
          },
          2000: {
            items: 6,
          },
        },
      });
    },
    shuffleCards() {
      for (let i = this.cards.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.cards[i], this.cards[j]] = [this.cards[j], this.cards[i]];
      }
    },
  },
};
</script>

<style lang="scss">
.user-carousel {
  &.odd {
    @media (min-width: 950px) {
      margin-left: 210px;
    }
  }
  &.even {
  }
}
.card {
  padding: 20px 30px;
  margin: 20px;
  background-color: white;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}
.card--text {
  text-align: justify;
}
.card--picture img {
  // border: 2px solid #126c2b;
}
.name-and-rating {
  margin-left: 10px;
  position: absolute;
  top: 18px;
  left: 90px;
}
.card--name {
  height: 50px;
  line-height: 50px;
  vertical-align: bottom;
  font-size: 16px;
}
.card--date {
  font-size: 12px;
  bottom: 10px;
  right: 10px;
}
.tns-outer > button {
  display: none;
}
.card--rating {
  position: relative;
  display: block;
  top: -10px;
  left: 0;
  right: 0;
}
.card--rating img {
  width: 20px;
  height: 20px;
  padding-right: 3px;
}
.card--store {
  position: absolute;
  top: 25px;
  right: 25px;
  @media (max-width: 1099px) {
    top: 15px;
    right: 15px;
  }
}
/* .tns-ovh { margin-left: -20px; margin-right: 20px; } */
._img-transition > * {
  position: absolute;
  background-color: white;
  left: 0;
}

._img-transition .second {
  animation-name: cf3FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 5s;
  animation-direction: alternate;
}

@keyframes cf3FadeInOut {
  0% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.tns-inner {
  position: relative;

  &:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 200px;
    box-shadow: 170px -1px 54px -71px #f7f7f7 inset;
    z-index: 30;
  }
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 200px;
    box-shadow: -170px -1px 54px -71px #f7f7f7 inset;
    z-index: 30;
  }
  @media (max-width: 639px) {
    &:before,
    &:after {
      display: none;
    }
  }
}
</style>
