<template>
  <div class="super-container" ref="container">
    <div class="first-half">
      <header>
        <MainMenu />
        <FirstSlide />
      </header>
    </div>

    <AppShowcase ref="showcase" />

    <div class="line-100px only-desktop"></div>

    <UserReviews />

    <footer>
      <FooterLinks />
    </footer>

    <GetApp ref="getApp" />
  </div>
</template>

<script>
import { config } from "@/config";
import Browser from "@/plugins/Browser.js";
import MainMenu from "@/components/MainMenu.vue";
import FirstSlide from "@/components/FirstSlide.vue";
import AppShowcase from "@/components/AppShowcase.vue";
import UserReviews from "@/components/UserReviews.vue";

import FooterLinks from "@/components/FooterLinks.vue";
// import FooterNav from "@/components/FooterNav.vue";
import GetApp from "@/components/GetApp.vue";

export default {
  name: "Landing",
  components: {
    MainMenu,
    FirstSlide,
    AppShowcase,
    UserReviews,
    FooterLinks,
    GetApp,
  },
  data() {
    return {
      doc: document,
      config: config,
      browser: {
        isWindows: Browser.isWindows(),
        isMac: Browser.isMac(),
        isIos: Browser.isIos(),
        isAndroid: Browser.isAndroid(),
        isChrome: Browser.isChrome(),
        isFirefox: Browser.isFirefox(),
        isSafari: Browser.isSafari(),
        isExplorer: Browser.isExplorer(),
        isEdge: Browser.isEdge(),
      },
    };
  },
  mounted() {
    window.scrollDirection = null;
    window.lastYOffset = 0;

    this.determineDevice();
    window.addEventListener("resize", this.determineDevice);
    window.addEventListener("orientationchange", this.determineDevice);
    window.addEventListener("scroll", this.determineScroll);

    let hash = window.location.hash.replace("#", "");
    if (hash.length > 1) {
      setTimeout(() => {
        this.goto(null, hash);
      }, 500);
    } // end if

    // this.$eventHub.$on("scrollTo", loc => {
    //   this.scrollTo(loc, 500);
    // });
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  },
  methods: {
    /* eslint-disable no-unused-vars */
    goto(e, id, ignoreOffset = false, callback) {
      let duration = 500;
      if (e && e.preventDefault) e.preventDefault();
      let offset = document.getElementById(id).offsetTop;
      scrollTo(offset - 60, duration);

      let loc = window.location.toString().replace(/#.*$/, "");
      window.history.replaceState({}, document.title, loc + "#" + id);

      if ("function" == typeof callback) {
        setTimeout(callback, duration + 100);
      } // end if

      return false;
    },
    /* eslint-enable no-unused-vars */
    scrollTo(to, duration) {
      var element = document.scrollingElement || document.documentElement,
        start = element.scrollTop,
        change = to - start,
        startDate = +new Date(),
        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        easeInOutQuad = function (t, b, c, d) {
          t /= d / 2;
          if (t < 1) return (c / 2) * t * t + b;
          t--;
          return (-c / 2) * (t * (t - 2) - 1) + b;
        }, // end func
        animateScroll = function () {
          var currentDate = +new Date();
          var currentTime = currentDate - startDate;
          element.scrollTop = parseInt(
            easeInOutQuad(currentTime, start, change, duration)
          );
          if (currentTime < duration) {
            requestAnimationFrame(animateScroll);
          } else {
            element.scrollTop = to;
          } // end if
        }; // end func
      animateScroll();
    },
    determineDevice() {
      const MOBILE_LIMIT = 768;
      if (/*Browser.isMobileDevice() ||*/ window.innerWidth <= MOBILE_LIMIT) {
        this.doc.body.classList.add("is-mobile");
        this.doc.body.classList.remove("is-desktop");
      } else {
        this.doc.body.classList.add("is-desktop");
        this.doc.body.classList.remove("is-mobile");
      } // end if-else
    },
    determineScroll() {
      let height = window.innerHeight;
      let scrolled = window.pageYOffset;
      let body = this.doc.body;

      if (scrolled > height) {
        body.classList.add("is-scrolled");
        body.classList.remove("is-not-scrolled");
      } else {
        body.classList.add("is-not-scrolled");
        body.classList.remove("is-scrolled");
      } // end if-else

      if (Math.abs(window.lastYOffset - scrolled) > 150) {
        if (window.lastYOffset < scrolled && scrolled > 100) {
          body.classList.add("scroll-down");
          body.classList.remove("scroll-up");
        } else {
          body.classList.add("scroll-up");
          body.classList.remove("scroll-down");
        } // end if-else

        window.lastYOffset = scrolled;
      } // end if
    },
    is_mobile() {
      return this.doc.body.classList.contains("is-mobile");
    },
    analyticsEvent(action, category, label, value) {
      if (!window.gtag) {
        return false;
      } // end if

      const params = {};
      if (category !== undefined) params.event_category = category;
      if (label !== undefined) params.event_label = label;
      if (value !== undefined) params.value = value;

      return window.gtag("event", action, params);
    },
  },
};
</script>

<!-- Cannot be scoped ATM (TODO) -->
<style>
@import "@/scss/reset.scss";
@import "@/scss/vars.scss";
@import "@/scss/layout.scss";
@import "@/scss/common.scss";
@import "@/scss/conditionals.scss";
@import "@/scss/lines.scss";
@import "@/scss/opacity.scss";
@import "@/scss/colors.scss";
@import "@/scss/typography.scss";
@import "@/scss/forms.scss";
@import "@/scss/animations.scss";
@import "@/scss/app.scss";
</style>

<style scoped lang="scss">
.first-half {
  z-index: 1000;
  position: relative;
  background: linear-gradient(180deg, #ffffff, #ffffff);
}
</style>