<template>
  <div>
    <section class="user-reviews" id="reviews">
      <div class="container">
        <article>
          <div class="column">
            <h2 class="h2 color-dark text-center">What our users say</h2>
            <p class="subheading color-dark text-center">
              Riders love our apps. And so will you.
            </p>
          </div>
          <div class="line-50px" />
          <UserCarousel :id="'01'" :type="'even'" :cards="reviews.firstLine" />
          <UserCarousel :id="'02'" :type="'odd'" :cards="reviews.secondLine" />
          <div class="line-50px" />
          <div class="column text-center">
            <div class="store pointer" v-on:click="openStore('https://play.google.com/store/apps/details?id=' + identifiers.googlePlay)">
              <div class="first-line">
                <img
                  :src="base + '/assets/img/google-play.png'"
                  height="50"
                  width="50"
                  alt="Google Play"
                />
                <span class="rating color-dark">4.4</span>
              </div>
              <div class="line-10px" />
              <div class="second-line">
                <img
                  class="star"
                  width="15"
                  height="15"
                  v-for="i in 4"
                  v-bind:key="i"
                  :src="base + '/assets/img/star.svg'"
                  alt="Star"
                />
                <img
                  class="star"
                  width="15"
                  height="15"
                  :src="base + '/assets/img/star-half.svg'"
                  alt="Star"
                />
              </div>
            </div>
            <div class="store pointer" v-on:click="openStore('https://apps.apple.com/cz/app/cyclers-bike-map-navigation/id' + identifiers.appStore)">
              <div class="first-line">
                <img
                  :src="base + '/assets/img/app-store.png'"
                  height="50"
                  width="50"
                  alt="AppStore"
                />
                <span class="rating color-dark">4.8</span>
              </div>
              <div class="line-10px" />
              <div class="second-line">
                <img
                  width="15"
                  height="15"
                  class="star"
                  v-for="i in 4"
                  v-bind:key="i"
                  :src="base + '/assets/img/star.svg'"
                  alt="Star"
                />
                <img
                  class="star"
                  width="15"
                  height="15"
                  :src="base + '/assets/img/star.svg'"
                  alt="Star"
                />
              </div>
            </div>
          </div>
        </article>
      </div>
    </section>
  </div>
</template>

<script>
import UserCarousel from "./UserCarousel.vue";

export default {
  name: "UserReviews",
  components: { UserCarousel },
  data() {
    const base = this.$parent.config.website.base;
    return {
      identifiers: {
        googlePlay: this.$parent.config.identifiers.googlePlay,
        appStore: this.$parent.config.identifiers.appStore,
      },
      base: this.$parent.config.website.base,
      reviews: {
        firstLine: [
          {
            name: "Justine Henry",
            review:
              "My fav app to plan and track rides. Love the badge system and route setting.",
            picture: base + "/assets/img/reviews/justine-henry.jpg",
            rating: 4.5,
            source: "googlePlay",
          },
          {
            name: "Costin Bogdan Andrei",
            review:
              "Very useful app. I love that you can give it the length of the ride that you'd like to take and it's going to generate a route or a circuit for that specific distance,",
            picture: base + "/assets/img/reviews/costin-bogdan-andrei.jpg",
            rating: 5.0,
            source: "googlePlay",
          },
          {
            name: "Rob L",
            review:
              "Great app, wanted an off ride route for me and my 9 year old, we live in quite a built up area and the app sent us off to places I didn't know about, mostly off road and very easy to follow. Highly recommend",
            picture: base + "/assets/img/reviews/rob-l.jpg",
            rating: 5.0,
            source: "appStore",
          },
          {
            name: "Holli Allen",
            review:
              "I really like the app. It's easy to use and tracks my distance & time plus average speed. It has helped me tremendously with my training.",
            picture: base + "/assets/img/reviews/holli-allen.jpg",
            rating: 4.5,
            source: "appStore",
          },
          {
            name: "Ben Steele",
            review: "Great app, routes have been accurate and I personally find it a real motivator. Already shaved 10 minutes of my morning commute!",
            picture: base + "/assets/img/reviews/ben-steele.jpg",
            rating: 5.0,
            source: "appStore",
          },
          {
            name: "Ben Appleby",
            review:
              "Gives me options to avoid traffic whilst also allowing me to make my own edits! ",
            picture: base + "/assets/img/reviews/ben-appleby.jpg",
            rating: 5.0,
            source: "googlePlay",
          },
        ],
        secondLine: [
          {
            name: "Johannes Mahlum",
            review:
              "Such an invaluable resource to get around a city safely on bike.",
            picture: base + "/assets/img/reviews/johannes-mahlum.png",
            rating: 4.5,
            source: "googlePlay",
          },
          {
            name: "Joan Manel Parrilla G.",
            review:
              "Perfect to discover new routes.",
            picture: base + "/assets/img/reviews/joan-manel-parrilla-gutierrez.jpg",
            rating: 5.0,
            source: "googlePlay",
          },
          {
            name: "Alyssa McMillan",
            review:
              "Awesome app. Takes me on nice rides around town. And when I need to get somewhere, it shows me the safest bike route with all the secret paths.",
            picture: base + "/assets/img/reviews/alyssa-mcmillan.jpg",
            rating: 5.0,
            source: "appStore",
          },
          {
            name: "Damon Kornegay",
            review:
              "Showing the best route, showing fast, safe, traffic and different areas route. ",
            picture: base + "/assets/img/reviews/damon-kornegay.jpg",
            rating: 4.5,
            source: "appStore",
          },
          {
            name: "Nora Petrova",
            review: "Really enjoying using this app! It finds really peaceful and quiet bike routes around town.",
            picture: base + "/assets/img/reviews/nora-petrova.jpg",
            rating: 5.0,
            source: "appStore",
          },
          {
            name: "Paul S",
            review:
              "I've tried quite a few cycling apps recently and this is the nicest-looking and most importantly it usually gives safer route options than rival apps",
            picture: base + "/assets/img/reviews/paul-s.jpg",
            rating: 5.0,
            source: "googlePlay",
          },
        ],
      },
    };
  },
  methods: {
    openStore(url) {
      window.location = url;
    }
  }
};
</script>

<style scoped lang="scss">
.user-reviews {
  padding: 50px 0;
  z-index: 100;
  margin: 100vh 0 0 0;
  background-color: #f7f7f7;
  min-height: calc(100vh - 100px);
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 0px -12px 25px 40px rgb(255 255 255);

  @media (max-width: 949px) {
    margin: 0;
  }

  .subheading {
    font-size: 16px;
    line-height: 32px;
  }
  .store {
    display: inline-block;
    margin: 0 30px;

    .first-line {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: center;
      align-items: center;
    }

    .rating {
      font-weight: 300;
      font-size: 40px;
      line-height: 59px;
      margin: 0 0 0 15px;
    }
    .star {
      height: 15px;
      margin: 0 3px;
    }
  }

}
</style>