<template>
  <div class="first-slide">
    <div class="container">
      <div class="column">
        <div class="pure-g tablet-center">
          <div class="pure-u-1-1 content">
            <div class="upper relative inline-block">
              <h1 class="h1 weight-400 color-white">
                <b>
                  Enjoyment in every ride
                </b>
              </h1>
              <div class="line-40px" />
              <p class="lead color-white tablet-padding">
                Next-generation bike navigation 
                <br class="only-desktop" />
                designed for cyclists.
              </p>
              <div class="line-40px" />
              <div class="cta-wrapper">
                <a
                  v-on:click.prevent="start()"
                  href="#start"
                  class="no-decoration cta btn bg-primary color-white"
                >
                  Take a tour
                  <img width="30" height="30" :src="base + '/assets/img/angle-down.svg'" alt="Down" />
                </a>
              </div>
            </div>

            <div class="badges">
              <a
                title="Download for Android"
                class="
                  badge badge-google
                  no-decoration
                  inline-block
                  margin-auto
                "
                :href="
                  'https://play.google.com/store/apps/details?id=' +
                  identifiers.googlePlay +
                  '&hl=en'
                "
              >
                <img
                  height="50"
                  width="167"
                  :src="base + '/assets/img/googleplay-badge-en.png'"
                  alt="Google Play Store"
                />
              </a>
              <a
                title="Download for iOS"
                class="badge badge-apple no-decoration inline-block margin-auto"
                :href="
                  'https://apps.apple.com/cz/app/cyclers-bike-map-navigation/id' +
                  identifiers.appStore
                "
              >
                <img
                  height="50"
                  width="167"
                  :src="base + '/assets/img/appstore-badge-en.svg'"
                  alt="Apple AppStore"
                />
              </a>
            </div>
            <a
              v-on:click.prevent="start()"
              class="arrow btn continue"
              href="#start"
            >
              <span class="inner">
                <img width="25" height="25"
                  :src="base + '/assets/img/angle-down.svg'"
                  alt="Angle down"
                />
                <img width="20" height="20"
                  :src="base + '/assets/img/angle-down.svg'"
                  alt="Angle down"
                />
                <img width="15" height="15"
                  :src="base + '/assets/img/angle-down.svg'"
                  alt="Angle down"
                />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstSlide",
  props: {},
  data() {
    return {
      doc: document,
      base: this.$parent.config.website.base,
      identifiers: {
        googlePlay: this.$parent.config.identifiers.googlePlay,
        appStore: this.$parent.config.identifiers.appStore,
      },
    };
  },
  methods: {
    start() {
      const loc = this.doc.querySelector("#start").offsetTop;
      this.$parent.scrollTo(loc, 500);
      this.$parent.$refs.showcase.$refs.controls.gotoSlide(1);
    },
  },
};
</script>

<style scoped lang="scss">
.first-slide {
  display: flex;
  align-content: center;
  // background-image: url("/public/assets/img/bg.jpg");
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("/public/assets/img/bg/optimized/bg_1280w.jpg");

  @media (min-width: 1280px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("/public/assets/img/bg/optimized/bg_1600w.jpg");
  }
  @media (min-width: 1600px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("/public/assets/img/bg/optimized/bg_2560w.jpg");
  }
  @media (min-width: 2560px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.4)), url("/public/assets/img/bg/optimized/bg_4096w.jpg");
  }

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: calc(100vh - 62px);
  
  // For responsive version of the website, we want FirstSlide to be 
  // 1px shorter than win.height so that IntersectionObserver can
  // correctly trigger intersection calback for first slide.
  @media (max-width: 949px) {
    min-height: calc(100vh - 20px);
  }

  letter-spacing: 1px;

  @media (max-width: 1023px) {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    @media (max-height: 799px) {
      display: block;
      padding-top: 50px;
      min-height: 500px;
    }
  }

  .badges {
    line-height: 45px;
    margin-top: 50px;
    position: absolute;
    bottom: 5px;
    left: calc(50% + 250px);
    .badge {
      margin-right: 15px;
    }
    .badge-google img {
      height: 50px;
    }
    .badge-apple img {
      height: 50px;
    }
    .lead {
      margin-bottom: 10px;
      font-size: 23px;
    }
    @media (max-width: 1279px) {
      transform: scale(0.75);
      transform-origin: right;
      left: initial;
      right: 50px;
    }
    @media (max-height: 799px) and (max-width: 1023px) {
      position: initial;
    }
    @media (max-width: 1023px) {
      transform-origin: center;
      padding: 25px;
      margin-top: 0px;

      left: 0;
      right: 0;
      bottom: 50px;

      .badge {
        margin: 0 10px;
      }
    }
  }
  .upper {
    z-index: 1;
    padding: 50px 50px 50px 0;

    @media (max-width: 1023px) {
      padding: 50px;
      margin-top: 0;
    }
    @media (max-width: 767px) {
      padding: 25px;
    }
  }

  @media (max-width: 1349px) and (min-width: 1024px) {
    .content {
      margin-left: 50px;
    }
  }

  .h1 {
    @media (max-width: 767px) {
      font-size: 36px;
      line-height: 42px;
    }
  }
  .lead {
    margin-bottom: {
    }
    @media (max-width: 767px) {
      font-size: 23px;
      line-height: 27px;
    }
  }
  .cta-wrapper {
    margin: 0;
    .cta {
      position: relative;
      padding: 20px 50px 20px 25px;
      font-weight: 500;
      font-size: 24px;
      line-height: 13px;
      border-radius: 20px;
      display: inline-block;

      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);

      img {
        position: absolute;
        height: 30px;
        right: 13px;
        top: 13px;
        animation: bounce 3s infinite;
      }
    }
  }

  .arrow {
    position: absolute;
    bottom: 20px;
    left: calc(50% - 31px);
    text-align: center;

    .inner {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 5px;
      border: 1px solid white;
      display: inline-block;

      img {
        display: block;
        margin: auto;
        animation: pulse;
        animation-duration: 3s;
        animation-iteration-count: infinite;

        &:nth-of-type(1) {
          width: 25px;
          animation-delay: 3s;
        }
        &:nth-of-type(2) {
          width: 20px;
          margin-top: -10px;
          animation-delay: 3.4s;
        }
        &:nth-of-type(3) {
          width: 15px;
          margin-top: -6px;
          animation-delay: 3.8s;
        }
      }
    }

    &:not(:hover) {
      opacity: 0.5;
    }

    @media (max-height: 799px) and (max-width: 1023px) {
      position: initial;
      display: block;
      transform: scale(0.75);
      transform-origin: bottom;
      margin-bottom: 25px;
    }
    @media (max-width: 767px) {
      transform: scale(0.75);
      transform-origin: center;
    }
  }

  // &:after {
  //   content: " ";
  //   display: block;
  //   z-index: 200;
  //   opacity: 0;
  //   animation: fadeout 1.5s;
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   height: 100vh;
  //   background-color: white;
  //   pointer-events: none;
  // }

  .container {
    @media (min-width: 1350px) {
      overflow: visible;
    }
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  // 75% {
  //   opacity: .5;
  // }
  100% {
    opacity: 0;
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(-2px);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    transform-origin: center center;
  }
  33% {
    transform: scale(1);
    transform-origin: center center;
  }
  50% {
    transform: scale(1.5);
    transform-origin: center center;
  }

  66% {
    transform: scale(1);
    transform-origin: center center;
  }
  100% {
    transform: scale(1);
    transform-origin: center center;
  }
}
</style>
