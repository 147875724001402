<template>
  <div class="countdown">
    <div
      v-if="display"
      ref="bar"
      class="bar"
      :style="{ transition: widthAnimation, width: width + 'vw' }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "PresentationSlideCountdown",
  props: ["activeSlide"],
  data() {
    return {
      win: window,
      duration: 0.0,
      display: false,
      width: 0,
    };
  },
  computed: {
    widthAnimation() {
      // return `width ${this.duration}s linear`;
      return `width ${this.duration}s ease-in-out`;
    },
  },
  mounted() {},
  methods: {
    start(duration) {
      const self = this;

      this.$forceUpdate();
      this.duration = duration;

      setTimeout(() => {
        self.display = true;

        setTimeout(() => {
          self.width = 100;
        }, 25);
      }, 25);
    },
    stop() {
      this.display = false;
      this.width = 0;
    },
  },
};
</script>

<style scoped lang="scss">
.countdown {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 2px;

  .bar {
    height: 2px;
    // width: 0%;
    // transition: width .25s ease-in-out;
    background-color: var(--color-primary);
  }
}
</style>
