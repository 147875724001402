<template>
    <div class="super-container" ref="container">
        <div class="first-half">
            <header>
                <MainMenu />
            </header>
        </div>

        <content>
            <div class="meta">
                <Breadcrumbs :crumbs="[{ title: 'Home', path: '/' }, { title: 'Places', path: `/places` }]" />
            </div>

            <section class="header">
                <img :src="base + '/assets/img/destinations/cyclist_panorama.jpg'" alt="Cyclist" />
                <div class="wave" />
                <h1 class="heading-1">
                    The Best Cycling Destinations
                </h1>
            </section>

            <section class="slide" data-nth="1">
                <template v-if="countries">
                    <div class="inner sitemap" v-for="places, lang in countries" v-bind:key="lang">
                        <h2 class="heading-2">{{ lang.toUpperCase() }}</h2>
                        <ul>
                            <li v-for="place, i in places" v-bind:key="i">
                                <router-link :to="`/places/${lang}/${place.url}`">{{ place.name }}</router-link>
                            </li>
                        </ul>
                    </div>
                </template>
                <div class="inner" v-else>
                    <div class="loader">
                        Loading...
                    </div>
                </div>
            </section>
        </content>

        <footer>
            <FooterLinks />
        </footer>

        <GetApp ref="getApp" />
    </div>
</template>

<script>
import { config } from "@/config";
import MainMenu from "@/components/MainMenu.vue";
import FooterLinks from "@/components/FooterLinks.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
    name: "DestinationsCatalogue",
    components: {
        MainMenu,
        FooterLinks,
        Breadcrumbs
    },
    data() {
        return {
            config: config,
            base: config.website.base,
            countries: null,
        };
    },
    mounted() {
        this.$http.get(`${config.website.api}/v1/places.json`).then((response) => {
            this.countries = response.data.countries;
        });
    },
    methods: {

    },
};
</script>

<style scoped lang="scss">
@import "@/scss/reset.scss";
@import "@/scss/vars.scss";
@import "@/scss/layout.scss";
@import "@/scss/common.scss";
@import "@/scss/conditionals.scss";
@import "@/scss/lines.scss";
@import "@/scss/opacity.scss";
@import "@/scss/colors.scss";
@import "@/scss/typography.scss";
@import "@/scss/forms.scss";
@import "@/scss/animations.scss";
@import "@/scss/app.scss";
@import "@/scss/destinations.scss";
</style>

<style scoped lang="scss">
.sitemap {
    h2 {
        margin-bottom: 10px !important;
    }
    ul {

        li {
            font-size: 20px;
            line-height: 25px;
            list-style-type: none !important;

            a {
                text-decoration: none;
                color: var(--color-primary);
            }

            &.lvl-1 {

            }
            &.lvl-2 {

            }
            &.lvl-3 {

            }
        }
    }
}


</style>
