<template>
  <div
    ref="showcase"
    class="app-showcase"
    :style="{
      opacity: superOpacity,
      'pointer-events': 0.0 == superOpacity ? 'none' : 'all',
      'z-index': superOpacity < .75 ? '-1' : 'initial',
    }"
  >
    <div class="phone-slide" :style="{ opacity: opacity }">
      <div ref="phone" class="phone" :class="{ sticky: sticky, 'is-windows': browser.isWindows }">
        <PresentationControls
          ref="controls"
          :activeSlide="activeSlide"
          :totalSlides="slides.length"
        />
        <PresentationSlideCountdown
          ref="countdown"
          :activeSlide="activeSlide"
        />
        <TryNow :isActive="null" :isSticky="sticky" />
        <img
          width="279"
          height="604"
          :src="base + '/assets/img/Frame.png'"
          alt="Phone"
        />
      </div>
    </div>
    <div ref="slides" class="slides" id="start">
      <PresentationSlide
        v-for="(slide, i) in slides"
        v-bind:key="i"
        :i="i"
        :slide="slide"
        :fakeSlide="i + 1 < slides.length"
        :sticky="sticky"
        :activeSlide="activeSlide"
        :isMobile="isMobile"
      />
    </div>
  </div>
</template>

<script>
import PresentationControls from "./PresentationControls.vue";
import PresentationSlideCountdown from "./PresentationSlideCountdown.vue";
import TryNow from "./TryNow.vue";
import PresentationSlide from "./PresentationSlide.vue";

export default {
  name: "AppShowcase",
  components: {
    PresentationControls,
    PresentationSlideCountdown,
    TryNow,
    PresentationSlide,
  },
  data() {
    const base = this.$parent.config.website.base;

    let canPlayVideo = true;
    const vid = document.createElement('video');
    if ('no' == vid.canPlayType('video/mp4; codecs=hvc1') && 'no' == vid.canPlayType('video/webm')) {
      canPlayVideo = false;
    } // end if

    return {
      canPlayVideo: canPlayVideo,
      base: base,
      enableAutoslide: false,
      doc: document,
      win: window,
      sticky: false,
      opacity: 0.0,
      superOpacity: 1.0,
      activeSlide: 0,
      isMobile: null,
      slides: [
        {
          heading: "Plan your bike rides in a few clicks",
          video: base + "/assets/video/rc3/slide01.webm",
          videoAlt: base + "/assets/video/rc3/slide01.mp4",
          videoFallback: base + "/assets/video/rc3/slide01.gif",
          list: [
            "The go-to bike route planner for personalised cycle routes.",
            "Find perfect circular routes with a single click.",
            "No need to add waypoints or know the area.",
          ],
          position: {
            heading: "topLeft",
            list: "bottomRight",
          },
          // startAt: 2.8,
          button: "bottomCenter",
          attachment: base + "/assets/img/cycler.png",
          attachmentSize: [442, 294],
          videoStops: [ [.1, 3], [4.66, 3], [9.5, 3]],
        },
        {
          heading: "Pick the route that best fits your requirements with Match",
          video: base + "/assets/video/rc3/slide02.webm",
          videoAlt: base + "/assets/video/rc3/slide02.mp4",
          videoFallback: base + "/assets/video/rc3/slide02.gif",
          list: [
            "Easily explore and compare multiple suggested routes with Match.",
            "Match shows how each route meets your preferences.",
            "See traffic, surface and climbs with route focus.",
          ],
          position: {
            heading: "topRight",
            list: "bottomLeft",
          },
          button: "bottomCenter",
          videoStops: [ [2.25, 3], [11, 3], [21, 3] ],
        },
        {
          heading: "Enjoy navigation designed specifically for cyclists",
          video: base + "/assets/video/rc3/slide03.webm",
          videoAlt: base + "/assets/video/rc3/slide03.mp4",
          videoFallback: base + "/assets/video/rc3/slide03.gif",
          list: [
            "Non-intrusive navigation that keeps you focused on you ride.",
            "Use lite mode when there is no upcoming change.",
            "Clear and precisely timed instructions that helps you reliably navigate your route.",
          ],
          position: {
            heading: "topLeft",
            list: "bottomRight",
          },
          button: "bottomCenter",
          attachment: base + "/assets/img/triangle.svg",
          attachmentSize: [546, 568],
          videoStops: [ [2, 3], [8, 3], [17, 3] ],
        },
        {
          heading: "Record & share your rides with your friends and family!",
          video: base + "/assets/video/rc3/slide04.webm",
          videoAlt: base + "/assets/video/rc3/slide04.mp4",
          videoFallback: base + "/assets/video/rc3/slide04.gif",
          list: [
            "Record your rides to build a diary of your favourite days on the bike.",
            "See and share comprehensive ride stats.",
            "Inspire other riders and show them the route you’ve taken.",
          ],
          position: {
            heading: "topRight",
            list: "bottomLeft",
          },
          button: "bottomCenter",
          // attachment: base + "/assets/img/socials.svg",
          videoStops: [ [1, 3], [7, 3], [12, 3] ],
        },
      ],
    };
  },
  computed: {
    browser() {
      return this.$parent.browser;
    },
  },
  watch: {
    activeSlide(newVal) {
      this.$parent.analyticsEvent('click', 'general', 'slide_view', newVal);
    },
  },
  mounted() {
    if ( ! Object.prototype.hasOwnProperty.call(HTMLMediaElement, 'playing') ) {
      Object.defineProperty(HTMLMediaElement.prototype, "playing", {
        get: function () {
          return !!(
            this.currentTime > 0 &&
            !this.paused &&
            !this.ended &&
            this.readyState > 2
          );
        },
      });
    } // end if
    
    // this.totalSlides = this.$refs.slides.querySelectorAll('.slide').length;
    this.observeSlides();
    this.detectMobile();
    window.addEventListener("resize", this.detectState);
    window.addEventListener("orientationchange", this.detectState);
    window.addEventListener("scroll", this.detectState);
  },
  methods: {
    detectState() {
      // const phone = this.$refs.phone;
      // const diff = (window.innerHeight - phone.clientHeight) / 2;
      const limitY = window.innerHeight;
      this.sticky = window.scrollY >= limitY;

      let perc = (window.innerHeight + window.scrollY) / window.innerHeight - 1;
      perc = perc * 2;
      // perc *= 2;
      let opacity = 0.0;
      if (perc > 1.0) {
        opacity = 1.0;
      } else {
        opacity = perc;
      }
      this.opacity = opacity;

      if (this.isMobile) {
        // this.activeSlide is Handled by IntersectionObserver
        this.superOpacity = 1.0;
      } else {
        this.activeSlide = (window.scrollY / window.innerHeight).toFixed(0);

        if (window.scrollY / window.innerHeight > this.slides.length) {
          let cutPerc = (window.scrollY / window.innerHeight) % 1;

          cutPerc = cutPerc * 2;
          let superOpacity = 100 - cutPerc * 100;

          if (superOpacity < 0.0) {
            superOpacity = 0.0;
          } // end if

          if (window.scrollY / window.innerHeight >= this.slides.length + 1) {
            superOpacity = 0.0;
          } // end if

          this.superOpacity = superOpacity / 100;
        } else {
          this.superOpacity = 1.0;
        } // end if-else
      } // end if-else

      this.detectMobile();
    },
    observeSlides() {
      const self = this;
      // TODO: UC Android browser doesn't support IntersectionObserver fully
      this.slides.forEach((slide, i) => {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (self.isMobile) {
              if (entry.intersectionRatio > 0) {
                self.activeSlide = i + 1;
              } else {
                // console.log(`${entry.intersectionRatio}%`, entry.target);
                if (0 == i && self.activeSlide == 1) {
                  self.activeSlide = 0;
                } // end if
              } // end if
            } // end if
          });
        });
        const el = this.doc.querySelector(`.slide-0${i + 1}`);
        observer.observe(el);
      });
    },
    detectMobile() {
      this.isMobile =
        this.win.innerWidth < this.$parent.config.website.responsiveBreak;
    },
    nextSlide() {
      if (this.enableAutoslide) {
        let activeSlide = parseInt(this.activeSlide);

        if (activeSlide < this.slides.length) {
          this.$refs.controls.gotoSlide(activeSlide + 1);
        } else {
          this.enableAutoslide = false;
        } // end if-else
      } // end if
    },
  },
};
</script>

<style scoped lang="scss">
.app-showcase {
  position: relative;
}
.phone-slide {
  min-height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  .phone {
    z-index: 100;
    width: 279px;
    height: 604px;
    // visibility: hidden;

    img {
      width: 279px;
      height: 604px;
    }

    // Testing
    &.is-windows:not(.sticky) { margin-left: 8px; }

    &.sticky {
      position: fixed;

      left: calc(50vw - 140px);
      top: calc(50vh - 302px);
      // visibility: visible;
    }

    &.visible {
      animation: fadein 1s;
    }
  }
  @media (max-width: 949px) {
    display: none;
  }
}

.slides {
  @media (min-width: 950px) {
    margin-top: -100vh;
  }
}
</style>
