// config.js

let config = {
    version: 'v1.0.1',
    website: {
        base: process.env.VUE_APP_BASE_URL,
        api: 'https://cyclers.app/api',
        app: 'https://cyclers.app/plan',
        responsiveBreak: 950
    },
    mapbox: {
        token: "pk.eyJ1IjoidW1vdGlvbmFscyIsImEiOiJjaW91MjhncHUwMDN6dmttMmRxeHdib2YyIn0.vl17KJSbS_-RDmTxxXsi3Q"
    },
    identifiers: {
        twitter: "cyclersapp",
        googlePlay: "com.umotional.bikeapp",
        appStore: "1213867271",
        ga: "G-NRHN4PC2EK"
    }
}

export { config }
