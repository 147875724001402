<template>
  <div class="controls">
    <span v-for="i in totalSlides" v-bind:key="i">
      <span
        v-on:click="gotoSlide(i)"
        class="btn"
        :class="{ active: i == activeSlide }"
      ></span>
    </span>
  </div>
</template>

<script>
export default {
  name: "PresentationControls",
  props: ["activeSlide", "totalSlides"],
  data() {
    return {
      win: window,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    gotoSlide(index) {
      let loc = index * this.win.innerHeight + 5;
      this.$parent.$parent.scrollTo(loc, 500);
    },
  },
};
</script>

<style scoped lang="scss">
.controls {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: -60px;

  .btn {
    background-color: var(--color-light);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin: 5px 5px;
    display: inline-block;
    cursor: pointer;

    &.active {
      background-color: var(--color-primary);
    }
    &:hover,
    &:focus,
    &:active {
      filter: brightness(90%);
    }
  }
  @media (max-height: 749px) {
    .controls {
      background-color: black;
      display: inline-block;
      margin: 2px 80px 0 80px;
      border-bottom-left-radius: 13px;
      border-bottom-right-radius: 13px;
      height: 26px;
    }
  }
}
</style>
