<template>
    <div class="share">
        <!-- AddToAny BEGIN -->
        <div class="a2a_kit a2a_kit_size_32 a2a_default_style">
            <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
            <a class="a2a_button_facebook"></a>
            <a class="a2a_button_x"></a>
            <a class="a2a_button_linkedin"></a>
        </div>
        <!-- AddToAny END -->
    </div>
</template>

<script>
export default {
    name: "ShareBtns",
    data() {
        return {

        };
    },
    mounted() {
        this.createTag();
    },
    methods: {
        createTag() {
            if (this.tagExists()) {
                return;
            }

            // Create script tag in the header
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://static.addtoany.com/menu/page.js';
            script.setAttribute('id', 'a2a_kit');
            document.head.appendChild(script);
        },
        tagExists() {
            return Boolean(document.getElementById('a2a_kit'));
        }
    },
};
</script>

<style scoped lang="scss">
.share {}
</style>
