<template>
  <a
    href="#footer"
    v-on:click.prevent="tryNow()"
    class="trynow no-decoration color-primary border-primary"
    :class="{active: isSticky}"
  >
    Try Now
    <img :src="base + '/assets/img/download.svg'" alt="Download" width="18" height="18" />
  </a>
</template>

<script>
export default {
  name: "TryNow",
  data() {
    return {
      base: this.$parent.base,
      doc: document,
    };
  },
  props: ["isActive", "isSticky"],
  methods: {
    tryNow() {
      // const loc = this.doc.querySelector("#footer").offsetTop;
      // this.$parent.$parent.scrollTo(loc, 500);
      this.$parent.$parent.$refs.getApp.show();
    },
  },
};
</script>

<style scoped lang="scss">
.trynow {
  width: 100px;
  z-index: 1000;
  position: absolute;
  bottom: -110px;
  left: calc(50% - 50px - 20px);
  padding: 12px 20px 15px 20px;
  font-weight: 500;
  font-size: 18px;
  border-radius: 30px;
  display: inline-block;
  background-color: white;
  opacity: 0;

  @media (max-height: 849px) {
    bottom: -70px;
    padding: 8px 20px 11px 20px;
  }

  &.active {
    animation: fadein 1s;
    animation-fill-mode: forwards;
  }
  &:not(.active) {
    // animation: fadeout 1s;
    // animation-fill-mode: forwards;
    opacity: 0;
    pointer-events: none;
  }

  img {
    width: 18px;
    position: relative;
    top: 3px;
    left: 3px;
  }
}
</style>
