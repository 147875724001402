<template>
  <div>
    <div
      class="slide"
      :class="[
        `slide-0${i + 1}`,
        {
          sticky: sticky,
          active: isActive,
          visited: visited,
          'is-windows': browser.isWindows,
        },
      ]"
    >
      <h2
        :class="[
          slide.position.heading,
          { active: isActive, visited: visited },
        ]"
        class="h2 color-dark"
      >
        {{ slide.heading }}
      </h2>
      <video v-if="isMobile" muted ref="video" playsinline>
        <template v-if="canPlayVideo">
          <!-- Safari -->
          <template v-if="browser.isMac && browser.isSafari">
            <source
              v-if="slide.videoAlt"
              :src="slide.videoAlt"
              type="video/mp4;codecs=hvc1"
            />
          </template>
          <!-- Chrome, Firefox, Opera, Edge -->
          <template v-else>
            <source :src="slide.video" type="video/webm" />
          </template>
        </template>
        <template v-else>
          <!-- Fallback -->
          <img :src="slide.videoFallback" alt="Planner" />
        </template>
      </video>
      <ul :class="slide.position.list" class="color-dark">
        <li
          :class="{ active: isActive, visited: visited }"
          v-for="(item, j) in slide.list"
          v-bind:key="j"
        >
          {{ item }}
        </li>
      </ul>
      <video v-if="!isMobile" muted ref="video" playsinline>
        <template v-if="canPlayVideo">
          <!-- Safari -->
          <template v-if="browser.isMac && browser.isSafari">
            <source
              v-if="slide.videoAlt"
              :src="slide.videoAlt"
              type="video/mp4;codecs=hvc1"
            />
          </template>
          <!-- Chrome, Firefox, Opera, Edge -->
          <template v-else>
            <source :src="slide.video" type="video/webm" />
          </template>
        </template>
        <template v-else>
          <!-- Fallback -->
          <img :src="slide.videoFallback" alt="Planner" />
        </template>
      </video>
      <div v-if="slide.attachment" class="attachment">
        <img
          :src="slide.attachment"
          alt="Attachment"
          :width="slide.attachmentSize[0]"
          :height="slide.attachmentSize[1]"
        />
      </div>
    </div>
    <div v-if="fakeSlide" class="fake-slide" />
  </div>
</template>

<script>
// import TryNow from "./TryNow.vue";

export default {
  name: "PresentationSlide",
  // components: {TryNow},
  props: ["slide", "i", "fakeSlide", "sticky", "activeSlide", "isMobile"],
  watch: {
    activeSlide(newActiveSlide, previousSlide) {
      const self = this;

      if (previousSlide != newActiveSlide) {
        this.clearStopTimers();
      } // end if

      if (1 + this.i == newActiveSlide) {
        let startTime = this.slide.startAt ? this.slide.startAt : 0.0;
        this.$refs.video.currentTime = startTime;

        if (this.slide.videoStops && this.slide.videoStops.length) {
          const listener = function () {
            self.$refs.video.removeEventListener("play", listener);
            self.planStops(self.slide.videoStops);
          };
          this.$refs.video.addEventListener("play", listener);
        } // end if

        this.$refs.video.play();
        // console.log(`Current video duration: ${this.$refs.video.duration}s`);

        this.$parent.$refs.countdown.stop();
        this.$parent.$refs.countdown.start(this.durationWithStops());
      } else {
        if (this.$refs.video.playing) {
          this.$refs.video.pause();
        } // end if
      }
    },
    isActive(is) {
      if (is && !this.visited) this.visited = true;
    },
  },
  mounted() {
    this.hijackSlideshow();
  },
  data() {
    return {
      base: this.$parent.$parent.config.website.base,
      doc: document,
      win: window,
      visited: false,
      stops: [],
      unpauses: [],
    };
  },
  computed: {
    isActive() {
      return this.i + 1 == this.activeSlide;
    },
    browser() {
      return this.$parent.$parent.browser;
    },
    canPlayVideo() {
      return this.$parent.canPlayVideo;
    },
    // isHidden() {
    //   return (this.i + 1) < 2 && this.activeSlide < 2 && ! this.sticky;
    // },
    // isMobile() {
    //   return this.win.innerWidth < this.$parent.$parent.config.website.responsiveBreak;
    // }
  },
  methods: {
    planStops(stops) {
      const self = this;
      // this.clearStopTimers();

      for (let i = 0; i < stops.length; i++) {
        const step = stops[i];
        let timestamp = step[0] * 1000;
        let duration = step[1] * 1000;

        // console.log(`At ${timestamp}ms, I will pause for ${duration}ms`);

        this.stops.push(
          setTimeout(() => {
            if (self.$refs.video.playing) {
              self.$refs.video.pause();
              self.unpauses.push(
                setTimeout(() => {
                  self.$refs.video.play();
                }, duration)
              );
            } // end if
          }, timestamp)
        );
      } // end for
      // stops.foreach((step) => {
      //   let timestamp = step[0] * 1000;
      //   let duration = step[1] * 1000;

      //   this.stops.push(
      //     setTimeout(() => {
      //       if (self.$refs.video.playing) {
      //         self.$refs.video.pause();
      //         console.log("pausing video...");
      //         setTimeout(() => {
      //           self.$refs.video.play();
      //           console.log("resuming video...");
      //         }, duration);
      //       } // end if
      //     }, timestamp)
      //   );
      // });
    },
    clearStopTimers() {
      if (this.stops.length) {
        this.stops.forEach((stop) => {
          clearTimeout(stop);
        });
      }
      if (this.unpauses.length) {
        this.unpauses.forEach((stop) => {
          clearTimeout(stop);
        });
      }
    },
    hijackSlideshow() {
      const self = this;
      this.$refs.video.addEventListener("ended", () => {
        var timeout = setTimeout(() => {
          self.$parent.nextSlide();
        }, 1500);

        self.win.addEventListener(
          "scroll",
          () => {
            clearTimeout(timeout);

            // self.win.removeEventListener(
            //   "scroll",
            //   arguments.callee
            // );
            self.$parent.enableAutoslide = false;
          },
          { once: true }
        );
        this.$parent.$refs.countdown.stop();
      });
      // this.$refs.video.addEventListener("play", (args) => {
      //   this.$parent.$refs.countdown.stop();
      //   this.$parent.$refs.countdown.start(this.durationWithStops());
      // });
    },
    durationWithStops() {
      let duration = this.$refs.video.duration;

      if (this.slide.videoStops !== undefined && this.slide.videoStops.length) {
        for (let i = 0; i < this.slide.videoStops.length; i++) {
          const step = this.slide.videoStops[i];
          let pause = step[1];

          duration += pause;
        } // end for
      } // end if

      return duration;
    },
  },
};
</script>

<style scoped lang="scss">
.slide {
  height: 100vh;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  margin: auto;
  overflow: hidden;

  @media (min-width: 950px) {
    &.sticky {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    &.invisible {
      visibility: hidden;
    }
  }

  @media (min-width: 950px) {
    &:not(.active) {
      opacity: 0;
      animation: fadeout 1s;
    }
    &.active {
      animation: fadein 1s;
      animation-fill-mode: forwards;
    }
  }
  @media (max-width: 949px) {
    &:not(.visited) {
      opacity: 0;
    }
    &.visited {
      animation: fadein 1s;
      animation-fill-mode: forwards;
    }
  }

  .h2 {
    min-width: 350px;
    width: 350px;
    max-width: 350px;
    opacity: 0;

    @media (min-width: 950px) {
      &.active {
        animation: fadein 1s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
      }
    }
    @media (max-width: 1199px) and (min-width: 950px) {
      transform: scale(0.8);
      left: 270px !important;
    }
    @media (max-width: 949px) {
      &.visited {
        animation: fadein 1s;
        animation-delay: 0.5s;
        animation-fill-mode: forwards;
      }
    }

    &.topLeft {
      left: 240px;
      top: -210px;
      @media (max-width: 1199px) and (min-width: 950px) {
        transform-origin: top right;
      }
    }
    &.topRight {
      left: 1005px;
      top: -210px;
      @media (max-width: 1199px) and (min-width: 950px) {
        transform-origin: top right;
      }
    }
  }
  video {
    width: 880px;
    height: 880px;
  }
  ul {
    z-index: -3;
    li {
      list-style-type: disc;
      margin-bottom: 25px;
      width: 350px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0px;
      opacity: 0;
      z-index: 1;

      @media (min-width: 950px) {
        &.active:nth-of-type(1) {
          animation: fadein 1s;
          animation-delay: 1s;
          animation-fill-mode: forwards;
        }
        &.active:nth-of-type(2) {
          animation: fadein 1s;
          animation-delay: 1.1s;
          animation-fill-mode: forwards;
        }
        &.active:nth-of-type(3) {
          animation: fadein 1s;
          animation-delay: 1.2s;
          animation-fill-mode: forwards;
        }
        &.active:nth-of-type(4) {
          animation: fadein 1s;
          animation-delay: 1.3s;
          animation-fill-mode: forwards;
        }
        &.active:nth-of-type(5) {
          animation: fadein 1s;
          animation-delay: 1.4s;
          animation-fill-mode: forwards;
        }
      }
      @media (max-width: 949px) {
        &.visited:nth-of-type(1) {
          animation: fadein 1s;
          animation-delay: 1s;
          animation-fill-mode: forwards;
        }
        &.visited:nth-of-type(2) {
          animation: fadein 1s;
          animation-delay: 1.1s;
          animation-fill-mode: forwards;
        }
        &.visited:nth-of-type(3) {
          animation: fadein 1s;
          animation-delay: 1.2s;
          animation-fill-mode: forwards;
        }
        &.visited:nth-of-type(4) {
          animation: fadein 1s;
          animation-delay: 1.3s;
          animation-fill-mode: forwards;
        }
        &.visited:nth-of-type(5) {
          animation: fadein 1s;
          animation-delay: 1.4s;
          animation-fill-mode: forwards;
        }
      }
    }

    &.bottomLeft {
      left: -80px;
      top: 210px;

      &:before {
        transform: rotate(-5deg);
        bottom: 20px;
        top: -80px;
        left: -60px;
        right: -490px;
      }
      &:after {
        left: -70px;
        top: -40px;
        bottom: -20px;
        right: -490px;
      }
    }
    &.bottomRight {
      left: 660px;
      top: 200px;

      &:before {
        transform: rotate(5deg);
        bottom: 20px;
        top: -80px;
        left: -490px;
        right: -40px;
      }
      &:after {
        left: -490px;
        top: -40px;
        bottom: -20px;
        right: -50px;
      }
    }
    &:before,
    &:after {
      content: " ";
      display: block;
      position: absolute;
      border-radius: 106px;
      z-index: -1;
    }

    @media (max-width: 1199px) and (min-width: 950px) {
      transform: scale(0.8);

      li {
        margin-left: 10px;
      }

      &.bottomLeft {
        left: -50px;

        &:before { transform: rotate(-5deg) scale(0.95); }
        &:after { transform: scale(0.95); }
        transform-origin: center right;
      }

      &.bottomRight {
        left: 630px;

        &:before { transform: rotate(5deg) scale(0.9); }
        &:after { transform: scale(0.9); }
        transform-origin: center left;
      }

    }
  }
  .h2,
  ul,
  video {
    position: relative;
  }
  video {
    pointer-events: none;
    z-index: -1;
  }
  .attachment {
    position: absolute;

    @media (max-width: 1199px) and (min-width: 950px) {
      transform: scale(0.8);
      transform-origin: center right;
    }
  }

  @media (max-width: 949px) {
    height: auto;
    flex-direction: column;
    border-bottom: 1px solid #e7e7e7;

    .h2,
    ul,
    video {
      left: initial !important;
      right: initial !important;
      top: initial !important;
      bottom: initial !important;
    }
    ul:before,
    ul:after {
      display: none !important;
    }
    .attachment {
      display: none !important;
    }
    .h2 {
      margin: 50px 0 0 0;
      min-width: auto;
      width: auto;
      max-width: 90%;
      text-align: center;
    }
    ul {
      padding: 0 50px 0 50px;
      margin-bottom: 25px;
      li {
        width: auto;
      }
    }
    video {
      margin: -85px 0 -85px 0;
      z-index: 100;
    }
  }
}

.fake-slide {
  min-height: 100vh;

  @media (max-width: 949px) {
    display: none;
  }
}

@import "@/scss/slide01.scss";
@import "@/scss/slide02.scss";
@import "@/scss/slide03.scss";
@import "@/scss/slide04.scss";
</style>
