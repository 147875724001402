import { createApp, h } from 'vue'
import App from './App.vue'
import router from './router/index.js';
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createI18n } from "vue-i18n";
import { dictionary } from "./locales/dictionary.js";
const i18n = createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: dictionary,
  });

const app = createApp({
    render: () => h(App)
});

app.use(router);
app.use(VueAxios, axios)
app.use(i18n)
app.mount('#app');
