<template>
    <div class="crumbs">
        <ul>
            <li v-for="item, i in crumbs" v-bind:key="i">
                <router-link :to="item.path" v-if="i < crumbs.length - 1">
                    {{ item.title }}
                </router-link>
                <span v-else>{{ item.title }}</span>
                <span class="sep" v-if="i < crumbs.length - 1">/</span>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "Breadcrumbs",
    data() {
        return {
            
        };
    },
    props: ["crumbs"],
    methods: {
        
    },
};
</script>

<style scoped lang="scss">
.crumbs {
    ul {
        li {
            display: inline-block;

            a {
                color: var(--color-primary);
            }

            .sep {
                margin: 0 0.5rem;
            }
        }
    }
    // @media (max-width: 1023px) {

    // }
}
</style>
