import { createRouter, createWebHistory } from 'vue-router'

import Landing from '@/pages/Landing.vue';
import Ambassador from '@/pages/Ambassador.vue';
import Positions from '@/pages/Positions.vue';
import DestinationsCatalogue from '@/pages/DestinationsCatalogue.vue';
import Destination from '@/pages/Destination.vue';
import SportegaLanding from '@/pages/SportegaLanding.vue';
import NotFound from '@/pages/NotFound.vue';

const routes = [
    {
        path: '/',
        component: Landing,
        meta: {
            title: "Cyclers | Next-generation bike navigation designed for cyclists"
        }
    },
    {
        path: '/ambassador',
        component: Ambassador,
        meta: {
            title: 'Ambassadors of Cyclers: Next-generation bike navigation designed for cyclists',
        }
    },
    {
        path: '/career',
        component: Positions,
        meta: {
            title: 'Join Cyclers | Apply to open positions',
        }
    },
    {
        path: '/places',
        component: DestinationsCatalogue,
        meta: {
            title: 'Cycling Destinations | Find the perfect cycling-friendly countries, cities, and regions with Cyclers',
        }
    },
    {
        path: '/places/:lang',
        redirect: () => {
            return { path: '/places' }
        },
    },
    {
        path: '/places/:lang/:slug(.*)*',
        component: Destination,
        meta: {
            title: '{{ title }} | {{ suffix }}',
        }
    },
    {
        path: '/sportega',
        component: SportegaLanding,
        meta: {
            title: '333...222...111 | Cyclers | Next-generation bike navigation designed for cyclists',
        }
    },
    {
        path: '/sportega/:lang',
        component: SportegaLanding,
        meta: {
            title: '333...222...111 | Cyclers | Next-generation bike navigation designed for cyclists',
        }
    },
    {
        path: '/:pathMatch(.*)*',
        component: NotFound,
        meta: {
            title: 'Not Found | Cyclers: Next-generation bike navigation designed for cyclists',
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const title = to.meta.title;
    if (title) {
        document.title = title;
    } // end if
    next();
});

export default router;