<template>
    <div ref="mapContainer" class="map-container"></div>
</template>

<script>
import { config } from "@/config";
import mapboxgl from "mapbox-gl";
mapboxgl.accessToken = config.mapbox.token;

export default {
    name: "Map",
    data() {
        return {
            map: null,
        };
    },
    mounted() {
        const map = new mapboxgl.Map({
            container: this.$refs.mapContainer,
            style: "mapbox://styles/umotionals/clhq60sdo01r401r0dmbzgnn4",
            center: this.center,
            zoom: 4,
        });

        this.map = map;
    },
    unmounted() {
        this.map.remove();
        this.map = null;
    },
    props: ["center"],
    methods: {
        getInstance() {
            return this.map;
        },
    },
};
</script>

<style scoped lang="scss">
.map-container {
    flex: 1;
    height: 800px;
    max-height: 90vh;
}
</style>
