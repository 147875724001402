<template>
  <div v-if="visible" class="getapp" id="getapp">
    <div
      v-on:click="hide()"
      class="bg"
      :class="{ visible: visible, closing: closing }"
    >
      <div
        v-on:click.stop
        class="content"
        :class="{ visible: visible, closing: closing }"
      >
        <span v-on:click="hide()" class="pointer close">
          <img
            :src="base + '/assets/img/times.svg'"
            alt="Close"
            title="Close"
          />
        </span>
        <div class="col text-center left">
          <div class="heading color-dark">Scan QR to download</div>
          <a
            :href="
              'https://play.google.com/store/apps/details?id=' +
              identifiers.googlePlay +
              '&hl=en'
            "
          >
            <img width="122" height="126" alt="QR Code" class="qr" :src="base + '/assets/img/qr.png'" />
          </a>
        </div>
        <div class="col text-center right">
          <div class="heading color-dark">Download from Store</div>
          <a
            v-on:click="$parent.analyticsEvent('click', 'general', 'planner_visit')"
            title="Online planner"
            class="badge badge-cyclers no-decoration inline-block margin-auto"
            href="https://cyclers.app/plan"
          >
            <img
              height="40"
              width="134"
              :src="base + '/assets/img/online-planner.png'"
              alt="Online planner"
            />
          </a>
          <br />
          <a
            v-on:click="$parent.analyticsEvent('click', 'general', 'google_plan_visit')"
            title="Download for Android"
            class="badge badge-google no-decoration inline-block margin-auto"
            :href="
              'https://play.google.com/store/apps/details?id=' +
              identifiers.googlePlay +
              '&hl=en'
            "
          >
            <img
              height="40"
              width="134"
              :src="base + '/assets/img/googleplay-badge-en.png'"
              alt="Google Play Store"
            />
          </a>
          <br />
          <a
            v-on:click="$parent.analyticsEvent('click', 'general', 'app_store_visit')"
            title="Download for iOS"
            class="badge badge-apple no-decoration inline-block margin-auto"
            :href="
              'https://apps.apple.com/cz/app/cyclers-bike-map-navigation/id' +
              identifiers.AppStore
            "
          >
            <img
              height="40"
              width="135"
              :src="base + '/assets/img/appstore-badge-en.svg'"
              alt="Apple AppStore"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GetApp",
  props: {},
  data() {
    return {
      win: window,
      visible: false,
      closing: false,
      base: this.$parent.config.website.base,
      identifiers: {
        googlePlay: this.$parent.config.identifiers.googlePlay,
        appStore: this.$parent.config.identifiers.appStore,
      },
    };
  },
  mounted() {
    const esc = 27;

    this.win.addEventListener("keyup", (e) => {
      if (esc == e.which) {
        this.hide();
      } // end if
    });
  },
  methods: {
    show() {
      this.visible = true;
      this.$parent.analyticsEvent('click', 'general', 'getapp_modal_view');
    },
    hide() {
      const self = this;
      this.closing = true;
      setTimeout(() => {
        self.visible = self.closing = false;
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
.getapp {
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .bg {
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(3px);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;

    &.visible {
      animation: fadein 0.5s;
      animation-fill-mode: forwards;
    }
    &:not(.visible),
    &.closing {
      animation: fadeout 0.5s;
      animation-fill-mode: forwards;
    }
    .content {
      position: relative;
      opacity: 0;
      padding: 30px 30px;
      background-color: white;
      border-radius: 20px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: row;

      .close {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 2px;
        width: 25px;
        height: 25px;
        display: flex;
      }

      .col {
        width: 200px;
        padding: 15px;
        display: inline-block;

        .heading {
          font-size: 18px;
          margin: 0 0 20px 0;
        }

        &.left {
          border-right: 1px solid var(--color-light);

          img {
            width: 122px;
            image-rendering: pixelated;
            image-rendering: -webkit-optimize-contrast;
          }
        }
        &.right {
          border-left: 1px solid transparent;
        }
      }

      &.visible {
        animation: fadein 0.5s;
        animation-fill-mode: forwards;
        animation-delay: .5s;
      }
    }
  }
}
</style>
